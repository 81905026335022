import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'spoton-lib';

import { PageLayout, Section, OptionTile, AddOption } from 'components';
import {
    getConfigVar,
    getNextStep,
    getPreviousStep,
    withLeadFetching,
} from 'utils';

import { DEMO_STEPS, EDemoSteps, ILeadPage } from 'types';
import { addPageSchema, slugify } from 'utils';
import {
    useGetLeadPagesQuery,
    useCreateLeadPagesMutation,
    useUpdateLeadPagesMutation,
    useUpdateLeadLastStepMutation,
} from 'services';
import { setCurrentStep } from 'store';

import { IPropTypes } from './PickPagesPage.types';
import { LoadingStatePages } from './LoadingState.component';
import styles from './PickPagesPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.pickPages];

function PickPagesPage(props: IPropTypes): JSX.Element {
    const { lead, router, isLoadingLead, isErrorLead } = props;

    const [url, setUrl] = useState('');

    const dispatch = useDispatch();

    const [updateLeadLastStep, { isSuccess: isSuccessUpdateLeadLastStep }] =
        useUpdateLeadLastStepMutation(router.query.id);

    const [
        createLeadPages,
        {
            isLoading: isLoadingCreateLeadPages,
            isError: isErrorCreateLeadPages,
        },
    ] = useCreateLeadPagesMutation(router.query.id);

    const [updateLeadPages, { isLoading: isLoadingUpdateLeadPages }] =
        useUpdateLeadPagesMutation(router.query.id);

    const { data: leadPages, isLoading: isLoadingLeadPages } =
        useGetLeadPagesQuery(router.query.id);

    useEffect(() => {
        if (!isLoadingLead && lead) {
            const defaultWebsiteUrl = getConfigVar(
                'REACT_APP_DEFAULT_WEBSITE_URL',
            );
            const isQaOrProd =
                !!getConfigVar('REACT_APP_IS_QA') ||
                !!getConfigVar('REACT_APP_IS_PROD');
            setUrl(
                `http${isQaOrProd ? 's' : ''}://${
                    lead.websiteUrl && isQaOrProd
                        ? lead.websiteUrl
                        : defaultWebsiteUrl
                }/demo/welcome?lead_id=${lead.id}`,
            );
            dispatch(setCurrentStep(EDemoSteps.pickPages));
        }
    }, [isLoadingLead]);

    useEffect(() => {
        if (isSuccessUpdateLeadLastStep && !isLoadingLead && !isErrorLead) {
            const leadId = lead.id;

            const redirectUrl = `/lead/${leadId}${
                DEMO_STEPS[getNextStep(EDemoSteps.pickPages)].url
            }`;
            router.push(redirectUrl);
        }
    }, [isSuccessUpdateLeadLastStep, isLoadingLead]);

    const handleOptionClick = (selectedPage: ILeadPage) => {
        updateLeadPages({
            currentStep: EDemoSteps.pickPages,
            lastStep: EDemoSteps.featuredImages,
            leadId: lead.id,
            pageId: selectedPage.id,
            isShown: !selectedPage.isShown,
        });
    };

    const handleRecommendedClick = (optionId: number) => {
        const selectedPage = leadPages?.recommended[optionId];
        if (selectedPage) {
            handleOptionClick(selectedPage);
        }
    };

    const handleOptionalClick = (optionId: number) => {
        const selectedPage = leadPages?.optional[optionId];
        if (selectedPage) {
            handleOptionClick(selectedPage);
        }
    };

    const handleAddOption = (option: string) => {
        if (!option) {
            return alert("Option can't be empty.");
        }

        const isDuplicate =
            leadPages?.recommended?.some(
                (page) => page.slug === slugify(option),
            ) ||
            leadPages?.optional?.some((page) => page.slug === slugify(option));

        if (!isDuplicate) {
            createLeadPages({
                currentStep: EDemoSteps.pickPages,
                lastStep: EDemoSteps.featuredImages,
                leadId: lead.id,
                name: option,
            });
        }
    };

    const hasSelectedPages =
        leadPages?.recommended.some((page) => page.isShown) ||
        leadPages?.optional.some((page) => page.isShown);

    const handlePickPagesContinue = () => {
        updateLeadLastStep({
            currentStep: EDemoSteps.pickPages,
            lastStep: EDemoSteps.pickPages,
            leadId: lead.id,
        });
    };

    const handleGoBack = () => {
        if (lead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getPreviousStep(EDemoSteps.pickPages)].url
            }`;
            router.push(redirectUrl);
        }
    };

    const renderWebsitePreview = () => {
        return (
            <div className={`${styles.Preview} ${styles.Preview___desktop}`}>
                <div className={styles.BrowserBar}>
                    <div className={styles.BrowserBar_dot}></div>
                    <div className={styles.BrowserBar_dot}></div>
                    <div className={styles.BrowserBar_dot}></div>
                </div>
                <iframe
                    src={url}
                    title="pages site preview"
                    className={styles.Preview_frame}
                    frameBorder="0"
                />
            </div>
        );
    };

    const renderFooterActions = (): JSX.Element => {
        return (
            <div className={styles.PickPagesPage_footer}>
                <Button
                    variant="secondary"
                    className={styles.Button}
                    disabled={
                        isLoadingCreateLeadPages || isLoadingUpdateLeadPages
                    }
                    onClick={handleGoBack}
                >
                    Back
                </Button>
                <Button
                    variant="primary"
                    className={styles.Button}
                    isLoading={
                        isLoadingCreateLeadPages || isLoadingUpdateLeadPages
                    }
                    disabled={
                        isLoadingCreateLeadPages ||
                        isErrorCreateLeadPages ||
                        isLoadingUpdateLeadPages ||
                        !hasSelectedPages
                    }
                    onClick={handlePickPagesContinue}
                >
                    Continue
                </Button>
            </div>
        );
    };

    const renderContent = (): JSX.Element => {
        return (
            <>
                <div className={styles.PickPagesPage_subtitle}>
                    <span
                        className={styles.PickPagesPage_span}
                        aria-label="pages list"
                    >
                        Recommended Pages
                    </span>
                </div>
                <ul className={styles.PickPagesPage_list}>
                    {leadPages &&
                        leadPages.recommended.map(
                            ({ name, slug, isShown }, idx) => (
                                <OptionTile
                                    key={slug}
                                    option={{ id: idx, value: name }}
                                    isSelected={isShown}
                                    onOptionClick={handleRecommendedClick}
                                />
                            ),
                        )}
                </ul>
                <div className={styles.PickPagesPage_subtitle}>
                    <span className={styles.PickPagesPage_span}>
                        Optional Pages
                    </span>
                </div>
                <ul className={styles.PickPagesPage_list}>
                    {leadPages &&
                        leadPages.optional.map(
                            ({ name, slug, isShown }, idx) => (
                                <OptionTile
                                    key={slug}
                                    option={{ id: idx, value: name }}
                                    isSelected={isShown}
                                    onOptionClick={handleOptionalClick}
                                />
                            ),
                        )}
                </ul>
                <div className={styles.PickPagesPage_inputGroup}>
                    <AddOption
                        label="Other"
                        helperText="If the page you are looking for is not on the list, please add it"
                        buttonText="Add Page"
                        shouldResetOnSubmit
                        validationSchema={addPageSchema}
                        onAddOption={handleAddOption}
                    />
                </div>
                {renderFooterActions()}
            </>
        );
    };

    return (
        <div data-testid="pick-pages-page" className={styles.PickPagesPage}>
            <PageLayout
                hasError={isErrorLead || isErrorCreateLeadPages}
                illustration={
                    (isLoadingUpdateLeadPages || isLoadingCreateLeadPages) &&
                    url ? (
                        <LoadingStatePages />
                    ) : (
                        renderWebsitePreview()
                    )
                }
            >
                <Section
                    title={'Pick Your Pages'}
                    className={styles.PickPagesPage_section}
                >
                    {isLoadingLead || isLoadingLeadPages ? (
                        <LoadingStatePages />
                    ) : (
                        renderContent()
                    )}
                </Section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, PickPagesPage);
