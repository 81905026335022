import { combineReducers } from '@reduxjs/toolkit';

import { leadApi } from 'services/lead';
import { categoryApi } from 'services/category';
import { serviceApi } from 'services/service';
import { stepperReducer, demolinkReducer, bookCallReducer } from 'store';
import { photosApi } from 'services/photos';
import { stylesApi } from 'services/styles';

const rootReducer = combineReducers({
    [leadApi.reducerPath]: leadApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [photosApi.reducerPath]: photosApi.reducer,
    [stylesApi.reducerPath]: stylesApi.reducer,
    stepper: stepperReducer,
    demoLink: demolinkReducer,
    bookCall: bookCallReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
