import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reduxConfig';

const stepperState = (state: RootState) => state.stepper;

export const selectCurrentStep = createSelector(
    stepperState,
    (state) => state.currentStep,
);
