import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'spoton-lib';

import { FormikInput, GoogleAutocomplete } from 'components';

import { IPropTypes } from './BusinessDetailForm.types';
import styles from './BusinessDetailForm.module.scss';

export function BusinessDetailForm(props: IPropTypes): JSX.Element {
    const {
        initialValues,
        validationSchema,
        isLoading,
        onSubmit,
        onBack,
        onPlaceChange,
    } = props;

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isValid }) => (
                <Form className={styles.BusinessDetailForm}>
                    <div className={styles.BusinessDetailForm_form}>
                        <FormikInput
                            id="businessName"
                            name="businessName"
                            label="Business Name"
                            placeholder="Business Name"
                        />
                        <FormikInput
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone"
                            placeholder="Phone"
                        />
                        {initialValues.address ? (
                            <GoogleAutocomplete
                                id="address"
                                name="address"
                                label="Address"
                                type="geocode"
                                onChange={onPlaceChange}
                                initialValue={initialValues.address}
                            />
                        ) : null}
                    </div>

                    <div className={styles.BusinessDetailForm_actions}>
                        <Button
                            variant="secondary"
                            onClick={onBack}
                            data-testid="cancelButton"
                            className={styles.Button}
                            disabled={isLoading}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={!isValid}
                            isLoading={isLoading}
                            data-testid="submitForm"
                            className={styles.Button}
                        >
                            Continue
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default BusinessDetailForm;
