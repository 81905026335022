import React, { useEffect } from 'react';
import { ToastContainer, showToast } from 'spoton-lib';

import { DomainForm } from 'components';
import { domainSchema, useRouter, useTldList } from 'utils';
import { useUpdateLeadDomainNameMutation } from 'services';
import { EDemoSteps } from 'types';

import { IPropTypes, ITldOption } from './ExistingDomain.types';
import styles from './ExistingDomain.module.scss';

export function ExistingDomain(props: IPropTypes): JSX.Element {
    const { leadId } = props;

    const router = useRouter();
    const tldList = useTldList();

    const [updateLeadDomainName, { isError, isLoading }] =
        useUpdateLeadDomainNameMutation();

    useEffect(() => {
        if (isError && !isLoading) {
            showToast({
                variant: 'danger',
                content: 'Something went wrong. Please try again.',
                containerId: 'domain-toast',
            });
        }
    }, [isError]);

    useEffect(() => {
        if (router.query?.domain) {
            showToast({
                variant: 'success',
                content: `${router.query.domain} is available`,
                containerId: 'domain-toast',
            });
        }
    }, [router.query?.domain]);

    const handleDomainSubmit = (domain: string, tld: string) => {
        updateLeadDomainName({
            name: domain,
            postfix: `.${tld}`,
            shouldValidate: false,
            leadId,
            currentStep: EDemoSteps.domainPreference,
            connect: true,
        }).then((result) => {
            if ('data' in result) {
                const { url } = router.match;
                const rootDomainUrl = url.substring(0, url.lastIndexOf('/'));

                router.push(
                    `${rootDomainUrl}/connected?domainName=${domain}.${tld}`,
                );
            }
        });
    };

    const getDomain = (): string | undefined => {
        const domainName = router.query?.domain as string;

        if (domainName) {
            return domainName.split('.')[0];
        }
    };

    const getDomainTld = (): ITldOption | undefined => {
        const domainName = router.query?.domain as string;

        if (domainName) {
            return {
                value: domainName.split('.')[1],
                label: `.${domainName.split('.')[1]}`,
            };
        }
    };

    const getInitialValues = () => {
        const domain = getDomain();

        if (domain) {
            return {
                domain: domain,
            };
        }
        return {
            domain: '',
        };
    };

    return (
        <div className={styles.ExistingDomainPageContent}>
            <DomainForm
                buttonText="Connect"
                label="Enter the domain you want to connect"
                onSubmit={handleDomainSubmit}
                tldList={tldList}
                defaultTld={getDomainTld()}
                validationSchema={domainSchema}
                initialValues={getInitialValues()}
                isLoading={isLoading}
                isReadOnly={!!router.query?.domain}
            />
            <div className={styles.Toast}>
                <ToastContainer
                    containerId="domain-toast"
                    stylePosition="relative"
                    className={styles.Toast_content}
                />
            </div>
        </div>
    );
}

export default ExistingDomain;
