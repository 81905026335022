import React, { Children, cloneElement } from 'react';

import { IPropTypes, IScreenPropTypes } from './SplitScreen.types';
import styles from './SplitScreen.module.scss';

import LeftScreen from './LeftScreen.component';
import RightScreen from './RightScreen.component';

export function SplitScreen(props: IPropTypes): JSX.Element {
    const { children } = props;

    if (!children) {
        return <></>;
    }

    // Children.map allows us to transform children before rendering them
    // more info here: https://reactjs.org/docs/react-api.html#reactchildrenmap
    const screens = Children.map(
        children,
        (child: React.ReactElement<IScreenPropTypes>) => {
            const { className } = child.props;
            return cloneElement(child, {
                className,
            });
        },
    );

    return <div className={styles.SplitScreen}>{screens}</div>;
}

SplitScreen.Left = LeftScreen;
SplitScreen.Right = RightScreen;

export default SplitScreen;
