import { rest } from 'msw';

import {
    ILead,
    ILeadPages,
    IPalette,
    IUpdateSiteStylePayload,
    EDemoSteps,
    EColorPalette,
    ICategory,
    IGoogleApiResponse,
    IUnsplashApiResponse,
    IFeaturedImageUpdatePayload,
    IUpdateBookCallPayload,
    IUpdateCategoryPayload,
    ISpotonService,
    IUpdateSpotonServicesPayload,
    IUpdateBusinessInfoPayload,
    IUpdateContactInfoPayload,
    ILeadService,
    IUpdateServicesResponse,
    IUpdateSiteLogoResponse,
} from 'types';
import {
    lead,
    categories,
    styles,
    googleApiResponse,
    imageMock,
    unsplashApiResponse,
    servicesMock,
    businessInfo,
    contactInformationMock,
    aboutLead,
    businessInformation,
    leadServices,
    leadPages,
} from 'mocks';

export const handlers = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const { id } = req.params;

        let response: ILead = lead;

        if (id === EDemoSteps.businessServices) {
            response = {
                ...lead,
                ...businessInfo,
                ...businessInformation,
                spotonServices: [1, 2, 3],
                services: leadServices.reduce<Array<string>>((acc, curr) => {
                    if (curr.isSelected) {
                        acc.push(curr.value);
                    }
                    return acc;
                }, []),
                lastStep: EDemoSteps.businessServices,
            };
        }

        if (id === EDemoSteps.domainPreference) {
            response = {
                ...lead,
                ...businessInfo,
                ...businessInformation,
                spotonServices: [1, 2, 3],
                services: leadServices.reduce<Array<string>>((acc, curr) => {
                    if (curr.isSelected) {
                        acc.push(curr.value);
                    }
                    return acc;
                }, []),
                lastStep: EDemoSteps.domainPreference,
            };
        }

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get('*/v1/category', (req, res, ctx) => {
        const response: ICategory[] = categories;

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get('*/v1/styles', (req, res, ctx) => {
        const response: IPalette[] = styles;

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get('*/v1/leads/:id/about-us', (req, res, ctx) => {
        return res(ctx.json({ ...aboutLead }), ctx.delay(50));
    }),

    rest.post('*/v1/leads/:id/contacts', (req, res, ctx) => {
        const response: IUpdateContactInfoPayload = {
            leadId: lead.id,
            currentStep: EDemoSteps.contactInfo,
            ...contactInformationMock,
        };

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.post('*/v1/leads/:id/domains', (req, res, ctx) => {
        const response: ILead = lead;

        return res(ctx.json(response), ctx.delay(50));
    }),
];

export const businessHandler = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const { id } = req.params;

        let response: ILead = { ...lead, lastStep: EDemoSteps.businessInfo };

        if (id === 'with-location') {
            response = {
                ...response,
                ...businessInfo,
            };
        }

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.patch('*/v1/leads/:id', (req, res, ctx) => {
        const response: IUpdateBusinessInfoPayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.businessInfo,
            businessName: businessInfo.businessName,
            location: businessInfo.location,
            googlePlacesId: businessInfo.googlePlacesId,
            phoneNumber: businessInfo.phoneNumber,
            geometry: businessInfo.geometry,
        };

        return res(ctx.json(response), ctx.delay(50));
    }),
];

export const aboutBusinessHandlers = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const { id } = req.params;

        let response: ILead = { ...lead, lastStep: EDemoSteps.spotonServices };

        if (id == 'with-services') {
            response = {
                ...response,
                ...businessInfo,
                spotonServices: [2, 3, 4],
            };
        }

        if (id == 'next-step') {
            response = {
                ...lead,
                ...businessInfo,
                ...businessInformation,
                lastStep: EDemoSteps.aboutBusiness,
                id,
            };
        }

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get('*/v1/leads/:id/about-us', (req, res, ctx) => {
        const response = { ...aboutLead };
        return res(ctx.json(response), ctx.delay(50));
    }),
];

export const businessServicesHandlers = [
    rest.get('*/v1/leads/:id/ai-services', (req, res, ctx) => {
        const response: ILeadService[] = [...leadServices];

        return res(ctx.json(response), ctx.delay(50));
    }),
    rest.put('*/v1/leads/:id/services', (req, res, ctx) => {
        const response: IUpdateServicesResponse = {
            ...lead,
            ...businessInfo,
            ...businessInformation,
            spotonServices: [1, 2, 3],
            services: leadServices.reduce<Array<string>>((acc, curr) => {
                if (curr.isSelected) {
                    acc.push(curr.value);
                }
                return acc;
            }, []),
            lastStep: EDemoSteps.businessServices,
        };

        return res(ctx.json(response), ctx.delay(50));
    }),
];

export const leadPagesHandlers = [
    rest.get('*/v1/leads/:id/pages', (req, res, ctx) => {
        const response: ILeadPages = leadPages;

        return res(ctx.json(response), ctx.delay(50));
    }),
    rest.post('*/v1/leads/:id/pages', (req, res, ctx) => {
        const response: ILeadPages = { ...leadPages };
        response.recommended.push({
            id: 5,
            name: 'Main Page',
            slug: 'main-page',
            isShown: true,
        });

        return res(ctx.json(response), ctx.delay(50));
    }),
    rest.patch('*/v1/leads/:id/pages/:pageId', (req, res, ctx) => {
        const response: ILeadPages = leadPages;
        response.recommended[0].isShown = false;

        return res(ctx.json(response), ctx.delay(50));
    }),
];

export const categoryHandlers = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const { id } = req.params;

        let response: ILead = { ...lead, lastStep: EDemoSteps.category };

        if (id === 'with-category') {
            response = {
                ...response,
                category: 2,
            };
        }

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get('*/v1/category', (req, res, ctx) => {
        const response: ICategory[] = categories;

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.post('*/v1/leads/:id/category', (req, res, ctx) => {
        const { id, body } = req.params;

        const response: IUpdateCategoryPayload = {
            leadId: id,
            currentStep: body.currentStep,
            categoryId: body.categoryId,
        };

        return res(ctx.json(response), ctx.delay(50));
    }),
];

export const stylesHandlers = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const response: ILead = { ...lead, lastStep: EDemoSteps.siteStyling };

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get('*/v1/styles', (req, res, ctx) => {
        const response: IPalette[] = styles;

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.post('*/v1/leads/:id/styles', (req, res, ctx) => {
        const payload: IUpdateSiteStylePayload = {
            leadId: lead.id,
            currentStep: EDemoSteps.siteStyling,
            style: EColorPalette.bold,
        };

        return res(ctx.json(payload), ctx.delay(50));
    }),

    rest.post('*/v1/leads/:id/logo', (req, res, ctx) => {
        const payload: IUpdateSiteLogoResponse = {
            ...lead,
            logoUrl: 'https://spoton.com/some-logo-url',
            lastStep: EDemoSteps.siteStyling,
        };

        return res(ctx.json(payload), ctx.delay(50));
    }),
];

export const serviceHandler = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const { id } = req.params;

        let response: ILead = {
            ...lead,
            lastStep: EDemoSteps.spotonServices,
        };

        if (id == 'with-services') {
            response = {
                ...response,
                spotonServices: [2, 3, 4],
            };
        }

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get('*/v1/spoton-services', (req, res, ctx) => {
        const response: ISpotonService[] = servicesMock;

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.post('*/v1/leads/:id/spoton-services', (req, res, ctx) => {
        const response: IUpdateSpotonServicesPayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.spotonServices,
            spotonServices: [1, 2, 3],
        };

        return res(ctx.json(response), ctx.delay(50));
    }),
];

export const imagesHandlers = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const { id } = req.params;

        const response: ILead = {
            ...lead,
            googlePlacesId: 'place-id',
            lastStep: EDemoSteps.featuredImages,
            masterWebsiteUrl: 'restaurant',
        };

        if (id !== lead.id) {
            return res(ctx.status(500), ctx.delay(50));
        }

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get('*/v1/google-places/:id', (req, res, ctx) => {
        const response: IGoogleApiResponse = googleApiResponse;

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.get(`https://api.unsplash.com/photos/random`, (req, res, ctx) => {
        const response: IUnsplashApiResponse = unsplashApiResponse;

        return res(ctx.json(response.data), ctx.delay(50));
    }),

    rest.post('*/v1/leads/:id/featured-images', (req, res, ctx) => {
        const payload: IFeaturedImageUpdatePayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.featuredImages,
            featuredImages: [imageMock],
        };

        return res(ctx.json(payload), ctx.delay(50));
    }),
];

export const contactHandlers = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const { id } = req.params;

        let response: ILead = { ...lead, lastStep: EDemoSteps.contactInfo };

        if (id === 'with-contact') {
            response = {
                ...response,
                contact: {
                    ...contactInformationMock,
                },
            };
        }

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.post('*/v1/leads/:id/contacts', (req, res, ctx) => {
        const response: IUpdateContactInfoPayload = {
            leadId: lead.id,
            currentStep: EDemoSteps.contactInfo,
            ...contactInformationMock,
        };

        return res(ctx.json(response), ctx.delay(50));
    }),
];

export const previewHandler = [
    rest.get('*/v1/leads/:id', (req, res, ctx) => {
        const response: ILead = {
            ...lead,
            websiteSlug: 'website-slug',
            lastStep: EDemoSteps.preview,
        };

        return res(ctx.json(response), ctx.delay(50));
    }),

    rest.patch('*/v1/leads/:id/book-call', (req, res, ctx) => {
        const response: IUpdateBookCallPayload = {
            leadId: lead.id,
            currentStep: EDemoSteps.preview,
            bookCall: 'NOW',
        };

        return res(ctx.json(response), ctx.delay(50));
    }),
];
