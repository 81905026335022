import React from 'react';

import { LightBulbIcon } from 'assets';

import { IPropTypes } from './StepperIllustration.types';
import styles from './StepperIllustration.module.scss';

export function StepperIllustration(props: IPropTypes): JSX.Element {
    const { children, title, text, className, hasIcon = true } = props;

    return (
        <div className={`${styles.StepperIllustration} ${className}`}>
            <div className={styles.StepperIllustration_children}>
                {children}
            </div>

            <div className={styles.StepperIllustration_message}>
                {hasIcon && <LightBulbIcon className={styles.Icon} />}
                <div className={styles.StepperDescription}>
                    {title && (
                        <h6 className={styles.StepperDescription_title}>
                            {title}
                        </h6>
                    )}
                    <p className={styles.StepperDescription_text}>{text}</p>
                </div>
            </div>
        </div>
    );
}

export default StepperIllustration;
