import React from 'react';
import { Button, IconButton } from 'spoton-lib';

import { IPropTypes } from './ShareDemoModal.types';
import styles from './ShareDemoModal.module.scss';

export function ShareDemoModal(props: IPropTypes): JSX.Element {
    const { link, onCopyLinkClick, onClose } = props;

    return (
        <div className={styles.ShareDemoModal}>
            <div className={styles.Close}>
                <IconButton
                    className={styles.Close_icon}
                    name="CloseIcon"
                    onClick={onClose}
                    alt="close"
                    disableBorder
                />
            </div>
            <div className={styles.Content}>
                <div className={styles.Header}>
                    <h2 className={styles.Header_title}>Share demo</h2>
                    <h3 className={styles.Header_subtitle}>
                        This link allows anyone to view your website
                    </h3>
                </div>
                <div className={styles.Link}>
                    <h3 className={styles.Link_text} data-testid="share-link">
                        {link}
                    </h3>
                </div>
                <div className={styles.Action}>
                    <Button
                        className={styles.Action_button}
                        type="button"
                        onClick={onCopyLinkClick}
                        data-testid="copy-link-button"
                        block
                    >
                        Copy link
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ShareDemoModal;
