import { ISpotonService } from 'types';

export const spotonServiceMock: ISpotonService = {
    id: 1,
    title: 'Offer online ordering',
    icon: 'FoodIcon',
};

export const servicesMock: ISpotonService[] = [
    spotonServiceMock,
    {
        id: 2,
        title: 'Reserve online',
        icon: 'CalendarIcon',
    },
    {
        id: 3,
        title: 'Offer a loyalty program',
        icon: 'LapsedCustomerIcon',
    },
    {
        id: 4,
        title: 'Show off your menu',
        icon: 'RestaurantsIcon',
    },
    {
        id: 5,
        title: 'Sell your products online',
        icon: 'ShoppingCartIcon',
    },
    {
        id: 6,
        title: 'Offer deals via Facebook, SMS and Email',
        icon: 'CampaignIcon',
    },
];
