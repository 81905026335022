import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getConfigVar, getUpdatedStep, slugify } from 'utils';
import {
    ILead,
    IRequestPayload,
    IUpdateLeadLastStepResponse,
    IUpdateBusinessInfoResponse,
    IUpdateBusinessInfoPayload,
    IUpdateCategoryPayload,
    IUpdateSpotonServicesResponse,
    IUpdateSpotonServicesPayload,
    IUpdateSiteStylePayload,
    IUpdateSiteStyleResponse,
    IUpdateContactInfoResponse,
    IFeaturedImageResponse,
    IFeaturedImageUpdatePayload,
    ICreateLeadPagesPayload,
    ICreateLeadPagesResponse,
    IUpdateLeadPagesPayload,
    IUpdateLeadPagesResponse,
    IUpdateContactInfoPayload,
    ICreateLeadPayload,
    ICreateLeadResponse,
    IUpdateBookCallPayload,
    IUpdateAboutBusinessInfoResponse,
    IUpdateAboutBusinessInfoPayload,
    IUpdateServicesResponse,
    IUpdateServicesPayload,
    ILeadService,
    ILeadPages,
    IUpdateSiteLogoPayload,
    IUpdateSiteLogoResponse,
    IUpdateLeadDomainNameResponse,
    IUpdateLeadDomainNamePayload,
    IUpdateGeneratedLeadPagesResponse,
    IUpdateGeneratedLeadPagesPayload,
} from 'types';

export const leadApi = createApi({
    reducerPath: 'lead',
    tagTypes: [
        'Lead',
        'FeaturedImage',
        'LeadServices',
        'LeadPages',
        'AboutLead',
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: getConfigVar('REACT_APP_API_URL') as string,
    }),
    endpoints: (build) => ({
        getLead: build.query<ILead, string>({
            query: (id) => `/leads/${id}`,
            providesTags: ['Lead'],
        }),
        getAboutLead: build.query<{ text: string }, string>({
            query: (id) => `/leads/${id}/about-us`,
            providesTags: ['AboutLead'],
        }),
        getLeadServices: build.query<ILeadService[], string>({
            query: (leadId) => `/leads/${leadId}/ai-services`,
            providesTags: ['LeadServices'],
        }),
        getLeadPages: build.query<ILeadPages, string>({
            query: (leadId) => `/leads/${leadId}/pages`,
            providesTags: ['LeadPages'],
        }),
        createLead: build.mutation<ICreateLeadResponse, ICreateLeadPayload>({
            query: (payload) => ({
                url: '/leads',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Lead'],
        }),
        createLeadPages: build.mutation<
            ICreateLeadPagesResponse,
            ICreateLeadPagesPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}/pages`,
                method: 'POST',
                body: payload,
            }),
            onQueryStarted({ leadId, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData(
                        'getLeadPages',
                        leadId,
                        (draft) => {
                            const optionalPages = draft.optional
                                ? [
                                      ...draft.optional,
                                      {
                                          name: patch.name,
                                          slug: slugify(patch.name),
                                          isShown: true,
                                      },
                                  ]
                                : [];
                            const recommendedPages = draft.recommended
                                ? [...draft.recommended]
                                : [];
                            Object.assign(draft, {
                                recommended: recommendedPages,
                                optional: optionalPages,
                            });
                        },
                    ),
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: ['LeadPages'],
        }),
        updateLeadLastStep: build.mutation<
            IUpdateLeadLastStepResponse,
            IRequestPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}`,
                method: 'PATCH',
                body: payload,
            }),
            onQueryStarted(
                { leadId, currentStep },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            currentStep,
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
        }),
        updateLeadBusinessInfo: build.mutation<
            IUpdateBusinessInfoResponse,
            IUpdateBusinessInfoPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['Lead'],
        }),
        updateAboutBusinessInfo: build.mutation<
            IUpdateAboutBusinessInfoResponse,
            IUpdateAboutBusinessInfoPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['Lead'],
        }),
        updateBusinessCategory: build.mutation<
            IUpdateBusinessInfoResponse,
            IUpdateCategoryPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}/category`,
                method: 'POST',
                body: payload,
            }),
            onQueryStarted(
                { leadId, currentStep, ...patch },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            categoryId: patch,
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: ['Lead', 'AboutLead', 'LeadServices'],
        }),
        updateSpotonServices: build.mutation<
            IUpdateSpotonServicesResponse,
            IUpdateSpotonServicesPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}/spoton-services`,
                method: 'POST',
                body: payload,
            }),
            onQueryStarted(
                { leadId, currentStep, ...patch },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            ...patch,
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
        }),
        updateFeaturedImages: build.mutation<
            IFeaturedImageResponse[],
            IFeaturedImageUpdatePayload
        >({
            query: ({ leadId, featuredImages }) => ({
                url: `/leads/${leadId}/featured-images`,
                method: 'POST',
                body: featuredImages,
            }),
            onQueryStarted(
                { leadId, currentStep, ...patch },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            ...patch,
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: ['Lead'],
        }),
        updateSiteStyle: build.mutation<
            IUpdateSiteStyleResponse,
            IUpdateSiteStylePayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}/styles`,
                method: 'POST',
                body: payload,
            }),
            onQueryStarted(
                { leadId, currentStep, ...patch },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            ...patch,
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
        }),
        updateSiteLogo: build.mutation<
            IUpdateSiteLogoResponse,
            IUpdateSiteLogoPayload
        >({
            query: ({ image, leadId }) => {
                return {
                    url: `/leads/${leadId}/logo`,
                    method: 'POST',
                    body: image,
                };
            },
            invalidatesTags: ['Lead'],
        }),
        updateContactInfo: build.mutation<
            IUpdateContactInfoResponse,
            IUpdateContactInfoPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}/contacts`,
                method: 'POST',
                body: payload,
            }),
            onQueryStarted(
                { leadId, currentStep, ...patch },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            ...patch,
                            contact: { ...draft },
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
        }),
        updateBookCall: build.mutation<null, IUpdateBookCallPayload>({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}/book-call`,
                method: 'PATCH',
                body: payload,
            }),
            onQueryStarted(
                { leadId, currentStep, ...patch },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            ...patch,
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
        }),
        updateLeadServices: build.mutation<
            IUpdateServicesResponse,
            IUpdateServicesPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}/services`,
                method: 'PUT',
                body: payload,
            }),
            onQueryStarted(
                { leadId, currentStep, ...patch },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            services: patch.services,
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: ['Lead', 'LeadServices'],
        }),
        updateLeadDomainName: build.mutation<
            IUpdateLeadDomainNameResponse,
            IUpdateLeadDomainNamePayload
        >({
            query: ({ leadId, shouldValidate = false, ...payload }) => ({
                url: `/leads/${leadId}/domains?should_validate=${shouldValidate}`,
                method: 'POST',
                body: payload,
            }),
            transformErrorResponse: (response) => {
                if (
                    (response.data as any)?.error &&
                    'non_field_errors' in (response.data as any)?.error
                ) {
                    return {
                        message: (response.data as any).error
                            .non_field_errors[0],
                    };
                }

                return response;
            },
            onQueryStarted(
                { leadId, currentStep },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: ['Lead'],
        }),
        updateLeadPages: build.mutation<
            IUpdateLeadPagesResponse,
            IUpdateLeadPagesPayload
        >({
            query: ({ leadId, pageId, ...payload }) => ({
                url: `/leads/${leadId}/pages/${pageId}`,
                method: 'PATCH',
                body: payload,
            }),
            onQueryStarted(
                { leadId, currentStep, ...patch },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    leadApi.util.updateQueryData('getLead', leadId, (draft) => {
                        Object.assign(draft, {
                            ...patch,
                            lastStep: getUpdatedStep(
                                currentStep,
                                draft.lastStep,
                            ),
                        });
                    }),
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: ['LeadPages'],
        }),
        updateGeneratedLeadPages: build.mutation<
            IUpdateGeneratedLeadPagesResponse,
            IUpdateGeneratedLeadPagesPayload
        >({
            query: ({ leadId, ...payload }) => ({
                url: `/leads/${leadId}/regenerate-website`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetLeadQuery,
    useGetAboutLeadQuery,
    useGetLeadServicesQuery,
    useGetLeadPagesQuery,
    useCreateLeadMutation,
    useCreateLeadPagesMutation,
    useUpdateLeadLastStepMutation,
    useUpdateLeadBusinessInfoMutation,
    useUpdateAboutBusinessInfoMutation,
    useUpdateBusinessCategoryMutation,
    useUpdateSpotonServicesMutation,
    useUpdateFeaturedImagesMutation,
    useUpdateSiteStyleMutation,
    useUpdateContactInfoMutation,
    useUpdateBookCallMutation,
    useUpdateLeadServicesMutation,
    useUpdateLeadPagesMutation,
    useUpdateSiteLogoMutation,
    useUpdateLeadDomainNameMutation,
    useUpdateGeneratedLeadPagesMutation,
} = leadApi;
