import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import buildingWebsiteAnimation from './building_website.json';

import { useMedia } from 'utils';

import styles from './LeadContactPage.module.scss';

export function BuildingWebsite(): JSX.Element {
    const isDesktop = useMedia<boolean>(['(min-width: 1025px)'], [true], false);

    return (
        <div
            className={styles.BuildingWebsite}
            data-testid="building-animation"
        >
            <Player
                className={styles.BuildingWebsite_animation}
                src={buildingWebsiteAnimation}
                autoplay
                loop
            />
            <p
                className={`${styles.BuildingWebsite_title} ${
                    isDesktop ? '' : styles.BuildingWebsite_title___mobile
                }`}
            >
                We’re building your AI website
            </p>
        </div>
    );
}

export default BuildingWebsite;
