import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './PickPagesPage.module.scss';

export function LoadingStatePages(): JSX.Element {
    return (
        <div className={styles.LoadingState} data-testid="pages-loaders">
            <ContentLoader speed={1} viewBox="0 0 638 669">
                <rect x="10" y="0" rx="4" ry="4" width="100%" height="62" />
                <rect x="10" y="78" rx="4" ry="4" width="100%" height="62" />
                <rect x="10" y="156" rx="4" ry="4" width="100%" height="62" />
                <rect x="10" y="234" rx="4" ry="4" width="100%" height="62" />
                <rect x="10" y="312" rx="4" ry="4" width="100%" height="62" />
            </ContentLoader>
        </div>
    );
}

export default LoadingStatePages;
