import React from 'react';

import { CheckSolidBorderedIcon } from 'assets';

import { EFeaturedImageSource } from 'types';

import { IPropTypes } from './ImagePicker.types';
import styles from './ImagePicker.module.scss';

export function ImagePicker(props: IPropTypes): JSX.Element {
    const { image, isSelected, onClick } = props;
    const { url, source } = image;

    const computeSelectedClasses = (): string =>
        `${isSelected && styles.ImagePicker___active}`;

    const label =
        source === EFeaturedImageSource.google
            ? 'Your business'
            : 'Stock image';

    return (
        <button
            aria-checked={isSelected}
            role="checkbox"
            className={`${styles.ImagePicker} ${computeSelectedClasses()}`}
            onClick={() => onClick(image)}
        >
            <img className={styles.ImagePicker_image} src={url} alt={label} />

            <div className={styles.Label}>
                <span className={styles.Label_text}>{label}</span>
            </div>

            <div className={styles.ImagePicker_highlight}></div>

            {isSelected && (
                <CheckSolidBorderedIcon
                    className={styles.ImagePicker_checkIcon}
                />
            )}
        </button>
    );
}

export default ImagePicker;
