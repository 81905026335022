import { IBusinessDetail, IBusinessInfo } from 'types';

export const businessDetailInitValue: IBusinessDetail = {
    businessName: '',
    address: '',
    phoneNumber: '',
};

export const businessDetail: IBusinessDetail = {
    businessName: 'Business Name',
    address: 'Some address',
    phoneNumber: '1234567890',
};

export const businessInfo: IBusinessInfo = {
    businessName: 'Paris Las Vegas',
    phoneNumber: '(877) 796-2096',
    location: '3655 S Las Vegas Blvd, Las Vegas, NV 89109, USA',
    googlePlacesId: 'ChIJAQAAAGzDyIARAVwe_ga0REU',
    geometry: {
        lat: 36.1125414,
        lng: -115.1706705,
    },
};
