import React, { useEffect, useState } from 'react';
import { Facebook as DefaultLoader } from 'react-content-loader';
import { useDispatch } from 'react-redux';
import { Button } from 'spoton-lib';

import {
    AboutUs,
    OptionTile,
    PageLayout,
    Section,
    AddOption,
} from 'components';
import { DEMO_STEPS, EDemoSteps, ILeadService } from 'types';
import {
    addServiceSchema,
    getNextStep,
    getPreviousStep,
    withLeadFetching,
} from 'utils';
import { setCurrentStep } from 'store';
import {
    useGetAboutLeadQuery,
    useGetLeadServicesQuery,
    useUpdateLeadServicesMutation,
} from 'services';

import { IPropTypes } from './BusinessServicesPage.types';
import LoadingStateServices from './LoadingState.component';
import styles from './BusinessServicesPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.businessServices];

function BusinessServicesPage(props: IPropTypes): JSX.Element {
    const { lead, router, isLoadingLead, isErrorLead } = props;

    const [services, setServices] = useState<Array<ILeadService>>([]);

    const dispatch = useDispatch();

    const [
        updateLeadServices,
        {
            isSuccess: isSuccessBusinessServices,
            isLoading: isLoadingBusinessServices,
            isError: isErrorBusinessServices,
            isUninitialized: isUninitializedBusinessServices,
        },
    ] = useUpdateLeadServicesMutation(router.query.id);

    const {
        data: aboutLeadInfo,
        isLoading: isLoadingAboutLeadInfo,
        isError: isErrorAboutLeadInfo,
    } = useGetAboutLeadQuery(router.query.id, {
        skip: !isUninitializedBusinessServices,
    });

    const { data: leadServices, isLoading: isLoadingLeadServices } =
        useGetLeadServicesQuery(router.query.id, {
            skip: !isUninitializedBusinessServices,
        });

    const isLoadingAboutUsContent = isLoadingAboutLeadInfo || isLoadingLead;

    const hasSelectedServices = services.some((service) => service.isSelected);

    useEffect(() => {
        if (!isLoadingLead && !isErrorLead) {
            dispatch(setCurrentStep(EDemoSteps.businessServices));
        }
    }, [isLoadingLead]);

    useEffect(() => {
        if (isSuccessBusinessServices && !isLoadingLead && !isErrorLead) {
            const leadId = lead.id;

            const redirectUrl = `/lead/${leadId}${
                DEMO_STEPS[getNextStep(EDemoSteps.businessServices)].url
            }`;

            router.push(redirectUrl);
        }
    }, [isSuccessBusinessServices, isLoadingLead]);

    useEffect(() => {
        if (!lead || isLoadingLead || isLoadingLeadServices) {
            return;
        }

        const hasCatalogServices = leadServices && leadServices.length > 0;
        const totalServices = hasCatalogServices ? [...leadServices] : [];

        if (hasCatalogServices) {
            const addedByUser =
                lead.services?.reduce<Array<ILeadService>>((acc, service) => {
                    if (!leadServices.some((s) => s.value === service)) {
                        acc.push({ value: service, isSelected: true });
                    }
                    return acc;
                }, []) || [];

            totalServices.push(...addedByUser);
        }

        setServices(totalServices);
    }, [isLoadingLead, isLoadingLeadServices]);

    const getSelectedServices = () => {
        return services.reduce<Array<string>>((acc, service) => {
            if (service.isSelected) {
                acc.push(service.value);
            }
            return acc;
        }, []);
    };

    const handleGoBack = () => {
        if (lead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getPreviousStep(EDemoSteps.businessServices)].url
            }`;
            router.push(redirectUrl);
        }
    };

    const handleUpdateLeadServices = () => {
        updateLeadServices({
            currentStep: EDemoSteps.businessServices,
            lastStep: EDemoSteps.businessServices,
            leadId: lead.id,
            services: getSelectedServices(),
        });
    };

    const handleOptionClick = (optionId: number) => {
        const selectedService = services[optionId];
        setServices((prevState) =>
            prevState.map((service) => {
                if (service.value === selectedService.value) {
                    return {
                        ...service,
                        isSelected: !service.isSelected,
                    };
                }
                return service;
            }),
        );
    };

    const handleAddOption = (option: string) => {
        const isDuplicate = services.some(
            (service) => service.value === option,
        );

        if (!isDuplicate) {
            setServices((prevState) => [
                ...prevState,
                { value: option, isSelected: true },
            ]);
        }
    };

    const renderAboutUsContent = (): JSX.Element => {
        return (
            <AboutUs
                title={`About ${lead.businessName}`}
                description={aboutLeadInfo?.text || ''}
            />
        );
    };

    const renderFooterActions = () => {
        return (
            <div className={styles.BusinessServicesPage_footer}>
                <Button
                    variant="secondary"
                    className={styles.Button}
                    disabled={isLoadingBusinessServices}
                    onClick={handleGoBack}
                >
                    Back
                </Button>
                <Button
                    variant="primary"
                    className={styles.Button}
                    isLoading={isLoadingBusinessServices}
                    disabled={
                        isLoadingBusinessServices ||
                        isErrorBusinessServices ||
                        !hasSelectedServices
                    }
                    onClick={handleUpdateLeadServices}
                >
                    Continue
                </Button>
            </div>
        );
    };

    const renderContent = (): JSX.Element => {
        return (
            <div className={styles.BusinessServicesPageSection}>
                <ul
                    className={styles.BusinessServicesPageSection_services}
                    aria-label="services list"
                >
                    {services.map(({ value, isSelected }, idx) => (
                        <OptionTile
                            key={value}
                            option={{ id: idx, value }}
                            isSelected={isSelected}
                            onOptionClick={handleOptionClick}
                        />
                    ))}
                </ul>
                <div>
                    <AddOption
                        label="Other"
                        helperText="If the service is not on the list, please add it"
                        buttonText="Add Service"
                        shouldResetOnSubmit
                        validationSchema={addServiceSchema}
                        onAddOption={handleAddOption}
                    />
                </div>
                {renderFooterActions()}
            </div>
        );
    };

    return (
        <div
            data-testid="business-services-page"
            className={styles.BusinessServicesPage}
        >
            <PageLayout
                hasError={
                    isErrorLead ||
                    isErrorAboutLeadInfo ||
                    isErrorBusinessServices
                }
                illustration={
                    <div className={styles.BusinessServicesPage_illustration}>
                        {isLoadingAboutUsContent ? (
                            <DefaultLoader />
                        ) : (
                            renderAboutUsContent()
                        )}
                    </div>
                }
            >
                <Section
                    title={`What services does ${lead?.businessName} provide?`}
                    className={styles.BusinessServicesPage_section}
                >
                    {isLoadingLead ||
                    isLoadingBusinessServices ||
                    isLoadingLeadServices ? (
                        <LoadingStateServices />
                    ) : (
                        renderContent()
                    )}
                </Section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, BusinessServicesPage);
