import React from 'react';

import { IPropTypes } from './Section.types';
import styles from './Section.module.scss';

export function Section(props: IPropTypes): JSX.Element {
    const { title, subtitle, children, className, illustration } = props;

    return (
        <section className={`${styles.Section} ${className ? className : ''}`}>
            {illustration}

            {(title || subtitle) && (
                <div className={styles.Section_headings}>
                    {title && <h1 className={styles.Title}>{title}</h1>}
                    {subtitle && (
                        <h3 className={styles.Subtitle}>{subtitle}</h3>
                    )}
                </div>
            )}

            <div className={styles.Section_content}>{children}</div>
        </section>
    );
}

export default Section;
