import React from 'react';

import { CheckSolidBorderedIcon } from 'assets';
import { capitalize } from 'utils';

import { IPropTypes } from './PaletteTile.types';
import styles from './PaletteTile.module.scss';

import '@fontsource/asap-condensed/400.css';
import '@fontsource/caveat/400.css';
import '@fontsource/josefin-slab/400.css';
import '@fontsource/lato/400.css';
import '@fontsource/oxanium/400.css';
import '@fontsource/palanquin/400.css';
import '@fontsource/playfair-display/400.css';
import '@fontsource/ubuntu/400.css';

export function PaletteTile(props: IPropTypes): JSX.Element {
    const { palette, isSelected, onClick } = props;
    const {
        colorPalette,
        fontFamily,
        textColor,
        backgroundColor,
        colors,
        name,
    } = palette;

    const computeSelectedClasses = (): string =>
        `${isSelected ? styles.PaletteTileWrapper___active : ''}`;

    return (
        <button
            data-testid="palette-tile"
            aria-checked={isSelected}
            role="radio"
            className={`${
                styles.PaletteTileWrapper
            } ${computeSelectedClasses()}`}
            onClick={() => onClick(palette)}
        >
            <div className={styles.PaletteTile}>
                <div className={styles.PaletteTile_colors}>
                    <ul className={styles.PaletteTileColors}>
                        {colors.map((color, index) => (
                            <li
                                className={styles.PaletteTileColors_item}
                                key={`${colorPalette}-${color}-${index}`}
                                style={{ backgroundColor: color }}
                            />
                        ))}
                    </ul>
                </div>
                <div className={styles.PaletteTile_theme}>
                    <div
                        className={styles.PaletteTileTheme}
                        style={{
                            backgroundColor: `${backgroundColor}`,
                        }}
                    >
                        <p
                            className={styles.PaletteTileTheme_name}
                            style={{
                                fontFamily: `${fontFamily}`,
                                color: `${textColor}`,
                            }}
                        >
                            {capitalize(name)}
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.PaletteTileWrapper_overlay} />

            {isSelected && (
                <CheckSolidBorderedIcon
                    data-testid="palette-tile-selected"
                    className={styles.PaletteTileWrapper_checkIcon}
                />
            )}
        </button>
    );
}

export default PaletteTile;
