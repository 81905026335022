import React, { useEffect } from 'react';
import { Button } from 'spoton-lib';
import { useDispatch } from 'react-redux';

import { withLeadFetching } from 'utils';
import { setCurrentStep } from 'store';
import { EDemoSteps, DEMO_STEPS } from 'types';

import { PageLayout } from 'components';
import { TableIllustration } from 'assets';

import { IPropTypes } from './FinalDemoPage.types';
import styles from './FinalDemoPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.finalDemo];

export function FinalDemoPage(props: IPropTypes): JSX.Element {
    const dispatch = useDispatch();
    const { isLoadingLead } = props;

    useEffect(() => {
        if (!isLoadingLead) {
            dispatch(setCurrentStep(EDemoSteps.finalDemo));
        }
    }, [isLoadingLead]);

    const handleGoToWebsite = () => {
        window.location.href = 'https://www.spoton.com/products/website/';
    };

    const renderIllustration = () => {
        return (
            <div className={styles.Illustration_wrapper}>
                <div className={styles.Illustration_title}>
                    You’ll hear from us shortly.
                </div>

                <TableIllustration className={styles.Illustration} />
            </div>
        );
    };

    return (
        <div className={styles.FinalDemoPage}>
            <PageLayout illustration={renderIllustration()} hasWhiteBackground>
                <section className={styles.FinalDemoPage_section}>
                    <div className={styles.FinalDemoPage_title}>
                        You’ll hear from us shortly.
                    </div>
                    <div className={styles.FinalDemoPage_description}>
                        Our Sales Partners team has been contacted. You will
                        contacted as soon as possible.
                    </div>

                    <Button
                        className={styles.Button}
                        onClick={handleGoToWebsite}
                    >
                        {"I'm done"}
                    </Button>
                </section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, FinalDemoPage);
