import axiosDefault from 'axios';

import { getConfigVar } from './config.utils';

const axios = axiosDefault.create({
    baseURL: getConfigVar('REACT_APP_API_URL') as string,
    headers: {
        'Content-Type': 'application/json',
    },
});

export { axios };

export type { AxiosResponse } from 'axios';
