export interface IPalette {
    name: string;
    colorPalette: string;
    fontStyle: string;
    fontFamily: string;
    textColor: string;
    backgroundColor: string;
    colors: string[];
}

export enum EDemoStyle {
    professional = 'PROFESSIONAL',
    modern = 'MODERN',
    active = 'ACTIVE',
    healthy = 'HEALTHY',
    mindful = 'MINDFUL',
    fun = 'FUN',
}

export enum EColorPalette {
    bold = 'bold',
    modern = 'modern',
    bright = 'bright',
    active = 'active',
    outdoor = 'outdoor',
    lux = 'lux',
    ocean = 'ocean',
    crisp = 'crisp',
    monochrome = 'monochrome',
    night = 'night',
}

export enum EFontStyle {
    pairing1 = 'pairing_1',
    pairing2 = 'pairing_2',
    pairing3 = 'pairing_3',
    pairing4 = 'pairing_4',
    pairing5 = 'pairing_5',
    pairing6 = 'pairing_6',
}
