export interface IDemoStep {
    url: string;
    index: number;
}

export enum EDemoSteps {
    businessInfo = 'business_information',
    category = 'category',
    spotonServices = 'spoton_services',
    aboutBusiness = 'about_us',
    businessServices = 'business_services',
    featuredImages = 'featured_images',
    pickPages = 'pick_pages',
    siteStyling = 'site_styling',
    domainPreference = 'website_domain',
    contactInfo = 'contact_information',
    preview = 'preview',
    finalDemo = 'final_demo',
}

export const DEMO_STEPS: Record<EDemoSteps, IDemoStep> = {
    [EDemoSteps.businessInfo]: {
        url: '/business-information',
        index: 0,
    },
    [EDemoSteps.category]: {
        url: '/business-category',
        index: 1,
    },
    [EDemoSteps.spotonServices]: {
        url: '/spoton-services',
        index: 2,
    },
    [EDemoSteps.aboutBusiness]: {
        url: '/about-business',
        index: 3,
    },
    [EDemoSteps.businessServices]: {
        url: '/business-services',
        index: 4,
    },
    [EDemoSteps.featuredImages]: {
        url: '/featured-images',
        index: 5,
    },
    [EDemoSteps.pickPages]: {
        url: '/pick-pages',
        index: 6,
    },
    [EDemoSteps.siteStyling]: {
        url: '/site-styling',
        index: 7,
    },
    [EDemoSteps.domainPreference]: {
        url: '/domain',
        index: 8,
    },
    [EDemoSteps.contactInfo]: {
        url: '/contact',
        index: 9,
    },
    [EDemoSteps.preview]: {
        url: '/preview',
        index: 10,
    },
    [EDemoSteps.finalDemo]: {
        url: '/get-demo',
        index: 11,
    },
};
