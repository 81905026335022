import React, { useEffect, useState } from 'react';
import { Button } from 'spoton-lib';
import { useDispatch } from 'react-redux';

import {
    withLeadFetching,
    getNextStep,
    getPreviousStep,
    getConfigVar,
} from 'utils';
import {
    IUpdateSiteStylePayload,
    EDemoSteps,
    DEMO_STEPS,
    IPalette,
    IUpdateSiteLogoPayload,
} from 'types';
import {
    useUpdateSiteStyleMutation,
    useGetStylesQuery,
    useUpdateSiteLogoMutation,
} from 'services';
import { setCurrentStep } from 'store';

import { Section, PaletteTile, PageLayout, FileLoader } from 'components';

import { LoadingState } from './LoadingState.component';
import { IPropTypes } from './SiteStylingPage.types';
import styles from './SiteStylingPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.siteStyling];

export function SiteStylingPage(props: IPropTypes): JSX.Element {
    const dispatch = useDispatch();
    const { router, lead, isLoadingLead, isErrorLead } = props;
    const [selectedPalette, setSelectedPalette] = useState<IPalette>();
    const [selectedLogoImage, setSelectedLogoImage] = useState<File | null>();
    const [hasFileUploadError, setHasFileUploadError] = useState<boolean>();
    const [url, setUrl] = useState('');
    const [iframeSrc, setIframeSrc] = useState('');
    const [iframeKey, setIframeKey] = useState(0);
    const {
        data: paletteTiles,
        isFetching,
        isLoading: isLoadingPalette,
        isError: isErrorPalette,
    } = useGetStylesQuery(router.query.id);

    useEffect(() => {
        if (!isLoadingLead && !isErrorLead) {
            const defaultWebsiteUrl = getConfigVar(
                'REACT_APP_DEFAULT_WEBSITE_URL',
            );
            const isQaOrProd =
                !!getConfigVar('REACT_APP_IS_QA') ||
                !!getConfigVar('REACT_APP_IS_PROD');
            setUrl(
                `http${isQaOrProd ? 's' : ''}://${
                    lead.websiteUrl && isQaOrProd
                        ? lead.websiteUrl
                        : defaultWebsiteUrl
                }/demo/welcome?lead_id=${lead.id}`,
            );

            if (!isFetching) {
                const leadPalette = paletteTiles?.find(
                    (palette) => lead.style === palette.name.toUpperCase(),
                );
                setSelectedPalette(leadPalette);
            }

            dispatch(setCurrentStep(EDemoSteps.siteStyling));
        }
    }, [isLoadingLead, isFetching]);

    const [updateSiteStyle, { isSuccess, isLoading, isError: isErrorUpdate }] =
        useUpdateSiteStyleMutation();
    const [updateSiteLogo, { isLoading: isLoadingLogo, isError: isErrorLogo }] =
        useUpdateSiteLogoMutation();

    const handleStyleUpdate = () => {
        const payload: IUpdateSiteStylePayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.siteStyling,
            style: selectedPalette?.name.toUpperCase() || '',
        };

        updateSiteStyle(payload);
    };

    const handlePaletteSelect = (palette: IPalette): void => {
        setSelectedPalette(palette);
    };

    useEffect(() => {
        if (selectedPalette) {
            const queryParams = `&color_palette=${selectedPalette.colorPalette}&font_palette=${selectedPalette.fontStyle}`;

            setIframeSrc(url + queryParams);
        }
    }, [selectedPalette]);

    const handleGoBack = (): void => {
        if (lead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getPreviousStep(EDemoSteps.siteStyling)].url
            }`;
            router.push(redirectUrl);
        }
    };

    const handleFileUpload = (file: File | null) => {
        if (!file) {
            setSelectedLogoImage(null);
        }

        if (file) {
            setHasFileUploadError(false);
            setSelectedLogoImage(file);

            const fileData = new FormData();
            fileData.append('image', file);

            const logoPayload: IUpdateSiteLogoPayload = {
                leadId: lead?.id,
                image: fileData,
            };
            updateSiteLogo(logoPayload).then(() => {
                // "force" iframe reload
                setIframeKey((prevKey) => prevKey + 1);
            });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getNextStep(EDemoSteps.siteStyling)].url
            }`;
            router.push(redirectUrl);
        }
    }, [isSuccess]);

    const renderWebsitePreview = (): JSX.Element => {
        return (
            <div className={`${styles.Preview} ${styles.Preview___desktop}`}>
                <div className={styles.BrowserBar}>
                    <div className={styles.BrowserBar_dot}></div>
                    <div className={styles.BrowserBar_dot}></div>
                    <div className={styles.BrowserBar_dot}></div>
                </div>
                <iframe
                    src={iframeSrc}
                    key={iframeKey}
                    title="styled site preview"
                    className={styles.Preview_frame}
                    frameBorder="0"
                />
            </div>
        );
    };

    const renderFooterActions = () => {
        return (
            <div className={styles.SiteStylingPage_footer}>
                <Button
                    variant="secondary"
                    onClick={handleGoBack}
                    className={styles.Button}
                    disabled={isLoading || isLoadingLogo}
                >
                    Back
                </Button>
                <Button
                    variant="primary"
                    onClick={handleStyleUpdate}
                    className={styles.Button}
                    isLoading={isLoading || isLoadingLogo}
                    data-testid="next-button"
                >
                    Continue
                </Button>
            </div>
        );
    };

    return (
        <div className={styles.SiteStylingPage} data-testid="site-styling-page">
            <PageLayout
                hasError={
                    isErrorUpdate ||
                    isErrorPalette ||
                    isErrorLead ||
                    isErrorLogo ||
                    hasFileUploadError
                }
                illustration={renderWebsitePreview()}
                errorMessage={
                    hasFileUploadError
                        ? 'File exceeds 1 MB upload limit. Please select a smaller image.'
                        : ''
                }
            >
                <Section
                    className={styles.SiteStylingPage_mainSection}
                    title="Style your website"
                >
                    <FileLoader
                        imageCDN={lead?.logoUrl}
                        uploadFile={handleFileUpload}
                        backgroundColor={
                            selectedLogoImage
                                ? selectedPalette?.backgroundColor
                                : 'white'
                        }
                        setFileError={setHasFileUploadError}
                    />
                    <Section
                        className={styles.SiteStylingPage_paletteSection}
                        subtitle="Pick the colors that go best with your brand's personality"
                    >
                        {isLoadingLead || isLoadingPalette || isFetching ? (
                            <LoadingState />
                        ) : (
                            <div className={styles.Palettes}>
                                <ul
                                    className={styles.Palettes_list}
                                    aria-label="palettes list"
                                >
                                    {!isErrorLead && paletteTiles
                                        ? paletteTiles.map((palette) => (
                                              <PaletteTile
                                                  key={palette.colorPalette}
                                                  palette={palette}
                                                  isSelected={
                                                      selectedPalette?.colorPalette ===
                                                      palette.colorPalette
                                                  }
                                                  onClick={handlePaletteSelect}
                                              />
                                          ))
                                        : null}
                                </ul>
                                {renderFooterActions()}
                            </div>
                        )}
                    </Section>
                </Section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, SiteStylingPage);
