import React, { useEffect, useState } from 'react';
import { Button } from 'spoton-lib';
import { useDispatch } from 'react-redux';

import { getNextStep, withLeadFetching, getPreviousStep } from 'utils';
import { EDemoSteps, DEMO_STEPS, IUpdateCategoryPayload } from 'types';
import {
    useGetCategoriesQuery,
    useUpdateBusinessCategoryMutation,
} from 'services';

import {
    Section,
    StepperIllustration,
    CategoryTile,
    PageLayout,
} from 'components';
import { Categories } from 'assets';
import { setCurrentStep } from 'store';

import { LoadingStateCategory } from './LoadingState.component';
import { IPropTypes } from './BusinessCategoryPage.types';
import styles from './BusinessCategoryPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.category];

export function BusinessCategoryPage(props: IPropTypes): JSX.Element {
    const dispatch = useDispatch();

    const { router, lead, isLoadingLead, isErrorLead } = props;
    const [selectedCategory, setSelectedCategory] = useState<number | null>(
        null,
    );
    const {
        data: categories,
        isFetching,
        isLoading: isLoadingCategory,
        isError: isErrorCategory,
    } = useGetCategoriesQuery(router.query.id);

    useEffect(() => {
        if (!isLoadingLead && !isErrorLead) {
            setSelectedCategory(lead.category);

            dispatch(setCurrentStep(EDemoSteps.category));
        }
    }, [isLoadingLead]);

    const [
        updateBusinessCategory,
        { isSuccess, isLoading, isError: isErrorUpdate },
    ] = useUpdateBusinessCategoryMutation();

    const handleCategoryUpdate = () => {
        const payload: IUpdateCategoryPayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.category,
            categoryId: selectedCategory,
        };

        updateBusinessCategory(payload);
    };

    const handleCategorySelect = (categoryId: number): void => {
        setSelectedCategory(categoryId);
    };

    const handleGoBack = (): void => {
        if (lead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getPreviousStep(EDemoSteps.category)].url
            }`;
            router.push(redirectUrl);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getNextStep(EDemoSteps.category)].url
            }`;
            router.push(redirectUrl);
        }
    }, [isSuccess]);

    const renderFooterActions = () => {
        return (
            <div className={styles.BusinessCategoryPage_footer}>
                <Button
                    variant="secondary"
                    onClick={handleGoBack}
                    className={styles.Button}
                    disabled={isLoading}
                >
                    Back
                </Button>
                <Button
                    variant="primary"
                    onClick={handleCategoryUpdate}
                    className={styles.Button}
                    isLoading={isLoading}
                    disabled={!selectedCategory}
                >
                    Continue
                </Button>
            </div>
        );
    };

    return (
        <div
            data-testid="category-page"
            className={styles.BusinessCategoryPage}
        >
            <PageLayout
                hasError={isErrorCategory || isErrorUpdate || isErrorLead}
                illustration={
                    <StepperIllustration
                        hasIcon
                        title="Get the right look"
                        text="We’ll use images from our library that match your business category."
                        className={styles.Illustration_wrapper}
                    >
                        <Categories className={styles.Illustration} />
                    </StepperIllustration>
                }
            >
                <Section
                    className={styles.BusinessCategoryPage_section}
                    title="Please choose a category for your business"
                >
                    {isLoadingLead || isLoadingCategory || isFetching ? (
                        <LoadingStateCategory />
                    ) : (
                        <div
                            className={styles.Categories}
                            data-testid="category-section"
                        >
                            <ul
                                className={styles.Categories_lists}
                                aria-label="categories list"
                            >
                                {!isErrorLead && categories
                                    ? categories.map((category) => (
                                          <CategoryTile
                                              key={category.id}
                                              category={category}
                                              onCategoryClick={
                                                  handleCategorySelect
                                              }
                                              selectedCategory={
                                                  selectedCategory
                                              }
                                          />
                                      ))
                                    : null}
                            </ul>
                            {renderFooterActions()}
                        </div>
                    )}
                </Section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, BusinessCategoryPage);
