import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getConfigVar } from 'utils';
import { ISpotonService } from 'types';

export const serviceApi = createApi({
    reducerPath: 'service',
    tagTypes: ['Service'],
    baseQuery: fetchBaseQuery({
        baseUrl: getConfigVar('REACT_APP_API_URL') as string,
    }),
    endpoints: (build) => ({
        getSpotonServices: build.query<ISpotonService[], string>({
            query: () => `/spoton-services`,
            providesTags: ['Service'],
        }),
    }),
});

export const { useGetSpotonServicesQuery } = serviceApi;
