import React from 'react';

import { IScreenPropTypes } from './SplitScreen.types';
import styles from './SplitScreen.module.scss';

export function LeftScreen(props: IScreenPropTypes): JSX.Element {
    return (
        <div
            className={`${styles.SplitScreen_section} ${
                props.className ? props.className : ''
            }`}
        >
            {props.children}
        </div>
    );
}

LeftScreen.displayName = 'SplitScreen.Left';

export default LeftScreen;
