import React from 'react';
import ContentLoader from 'react-content-loader';

import { StepperIllustration } from 'components';
import styles from './BusinessCategoryPage.module.scss';

export function LoadingStateCategory(): JSX.Element {
    return (
        <div className={styles.LoadingState} data-testid="category-loader">
            <ContentLoader speed={1} viewBox="0 0 638 669">
                <rect x="10" y="0" rx="4" ry="4" width="100%" height="62" />
                <rect x="10" y="78" rx="4" ry="4" width="100%" height="62" />
                <rect x="10" y="156" rx="4" ry="4" width="100%" height="62" />
                <rect x="10" y="234" rx="4" ry="4" width="100%" height="62" />
                <rect x="10" y="312" rx="4" ry="4" width="100%" height="62" />
            </ContentLoader>
        </div>
    );
}

export function LoadingStateIllustration(): JSX.Element {
    return (
        <div className={styles.LoadingState}>
            <StepperIllustration
                hasIcon
                title="Get the right look"
                text="We’ll use images from our library that match your business category."
                className={styles.Illustration_wrapper}
            >
                <ContentLoader
                    className={styles.Illustration}
                    speed={1}
                    viewBox="0 0 596 327"
                >
                    <rect x="0" y="0" rx="4" ry="4" width="596" height="327" />
                </ContentLoader>
            </StepperIllustration>
        </div>
    );
}

export default LoadingStateCategory;
