import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reduxConfig';

const demoLinkState = (state: RootState) => state.demoLink;

export const getDemoLink = createSelector(
    demoLinkState,
    (state) => state.demoLink,
);
