import React, { useEffect, useRef, useState } from 'react';

import { useScript, getConfigVar } from 'utils';

import { IPropTypes } from './BusinessLocation.types';
import styles from './BusinessLocation.module.scss';

export function BusinessLocation(props: IPropTypes): JSX.Element {
    const { position } = props;

    const mapRef = useRef<HTMLInputElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [marker, setMarker] = useState<google.maps.Marker>();
    const [area, setArea] = useState<google.maps.Circle>();
    const status = useScript(
        `https://maps.googleapis.com/maps/api/js?key=${getConfigVar(
            'REACT_APP_PLACES_API_KEY',
        )}&libraries=places`,
    );

    const setPosition = (
        position: google.maps.LatLngLiteral | google.maps.LatLng,
    ): void => {
        if (!map) return;

        map.panTo(position);

        if (marker && area) {
            marker.setPosition(position);
            area.setCenter(position);
        } else {
            setMarker(
                new google.maps.Marker({
                    map,
                    position,
                }),
            );

            setArea(
                new google.maps.Circle({
                    strokeWeight: 0,
                    fillColor: '#B92624',
                    fillOpacity: 0.5,
                    map,
                    center: position,
                    radius: 500,
                }),
            );
        }
    };

    const initMap = (): void => {
        setMap(
            new google.maps.Map(mapRef.current as HTMLElement, {
                // default to 500 W Madison St, Chicago, IL
                center: {
                    lat: 41.88234681114315,
                    lng: -87.64003841516622,
                },
                zoom: 14,
            }),
        );
    };

    useEffect(() => {
        if ((window as any).google && status === 'ready') {
            initMap();
        }
    }, [status]);

    useEffect(() => {
        if ((window as any).google) {
            setPosition(position);
        }
    });

    return (
        <div className={styles.BusinessLocation}>
            <div
                data-testid="map"
                ref={mapRef}
                className={styles.BusinessLocation_map}
            />
        </div>
    );
}

export default BusinessLocation;
