/**
 * A helper for getting an env variable.
 *
 * When our project gets built for production we switch from the usual build
 * time ENV vars to run time variables injected via our build scripts, nginx,
 * and including it in the projects header.
 *
 * This allows us to set ENV vars whenever we want without the need to rebuild
 * and deploy, however it does mean a little extra work to get the right thing.
 * This method helps take out the guesswork, and gives a nice warning if a
 * variable is not set properly.
 *
 * @see /public/index.html
 * @see /env.sh
 */
export function getConfigVar(
    key: string,
): string | number | boolean | undefined {
    let env = process.env; // default env
    const isRuntimeEnv = process.env.NODE_ENV === 'production';

    if (isRuntimeEnv) {
        env = (window as any)._env_;
    }

    if (!env[key]) {
        // don't use logging util because we always want this warning on
        console.warn(`[Warn] Environment variable '${key}' is undefined`);

        return undefined;
    }

    return env[key];
}
