import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ResponsivePreview } from 'components';
import { withLeadFetching, getNextStep, useRouter, getConfigVar } from 'utils';
import { DEMO_STEPS, EDemoSteps, IUpdateBookCallPayload } from 'types';
import {
    setCurrentStep,
    setDemoLink,
    demolinkSelectors,
    bookCallSelectors,
    updateBookCallStatus,
} from 'store';
import { EBookCallStatus } from 'store/bookCall/bookCall.types';

import { useUpdateBookCallMutation } from 'services';
import { IPropTypes } from './PreviewPage.types';
import styles from './PreviewPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.preview];

export function PreviewPage(props: IPropTypes): JSX.Element {
    const dispatch = useDispatch();
    const { isLoadingLead, lead, router = useRouter() } = props;

    const defaultWebsiteUrl = getConfigVar('REACT_APP_DEFAULT_WEBSITE_URL');
    const demoLink = useSelector(demolinkSelectors.getDemoLink);
    const bookCallStatus = useSelector(bookCallSelectors.getBookCall);
    const isQaOrProd =
        !!getConfigVar('REACT_APP_IS_QA') ||
        !!getConfigVar('REACT_APP_IS_PROD');

    useEffect(() => {
        if (!isLoadingLead) {
            dispatch(setCurrentStep(EDemoSteps.preview));
            dispatch(
                setDemoLink(
                    `http${isQaOrProd ? 's' : ''}://${
                        lead.websiteUrl && isQaOrProd
                            ? lead.websiteUrl
                            : defaultWebsiteUrl
                    }/demo/welcome?lead_id=${lead.id}`,
                ),
            );
        }
    }, [isLoadingLead]);

    const [updateBookCall, { isSuccess }] = useUpdateBookCallMutation();

    useEffect(() => {
        if (bookCallStatus === EBookCallStatus.pending) {
            const payload: IUpdateBookCallPayload = {
                leadId: lead.id,
                currentStep: EDemoSteps.preview,
                bookCall: 'NOW',
            };

            updateBookCall(payload);
            dispatch(updateBookCallStatus(EBookCallStatus.requested));
        }
    }, [bookCallStatus]);

    useEffect(() => {
        if (isSuccess) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getNextStep(EDemoSteps.preview)].url
            }`;
            router.push(redirectUrl);
        }
    }, [isSuccess]);

    return (
        <div className={styles.PreviewPage} data-testid="preview-page">
            <ResponsivePreview src={demoLink} />
        </div>
    );
}

export default withLeadFetching(currentStep, PreviewPage);
