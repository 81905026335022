import { EDemoSteps, ILead } from 'types';

export const leadEmptyState = (): ILead => ({
    id: '',
    businessName: '',
    phoneNumber: '',
    location: '',
    googlePlacesId: '',
    lastStep: EDemoSteps.businessInfo,
    category: 0,
    bookCall: 'NOW',
    spotonServices: [],
    featuredImages: [],
    style: '',
    contact: {
        name: '',
        email: '',
        phoneNumber: '',
    },
    logoUrl: '',
    websiteUrl: '',
});
