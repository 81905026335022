/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, ComponentType, FunctionComponent } from 'react';

import { getArrayFromStepsEnum, useRouter } from 'utils';
import { IDemoStep, DEMO_STEPS, IWithLeadFetching, EDemoSteps } from 'types';
import { useGetLeadQuery } from 'services/lead';

export function withLeadFetching<T extends IWithLeadFetching>(
    currentStep: IDemoStep,
    WrappedComponent: ComponentType<T>,
): FunctionComponent<Omit<T, keyof IWithLeadFetching>> {
    const displayName =
        WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const ComponentWithLeadFetching: FunctionComponent<
        Omit<T, keyof IWithLeadFetching>
    > = (props): JSX.Element => {
        const router = useRouter();

        const {
            data: lead,
            isLoading,
            isFetching,
            isError,
        } = useGetLeadQuery(router.query.id);

        useEffect(() => {
            if (lead) {
                const lastStep: IDemoStep = DEMO_STEPS[lead.lastStep];

                if (lastStep.index < currentStep.index - 1) {
                    const redirectIndex = lastStep.index + 1;
                    let stepToRedirect = DEMO_STEPS[EDemoSteps.contactInfo];
                    getArrayFromStepsEnum().forEach((key) => {
                        if (DEMO_STEPS[key].index === redirectIndex) {
                            stepToRedirect = DEMO_STEPS[key];
                        }
                    });
                    router.replace(
                        `/lead/${router.query.id}${stepToRedirect.url}`,
                    );
                }
            }
        }, [lead]);

        return (
            <WrappedComponent
                {...(props as T)}
                isLoadingLead={isLoading || isFetching}
                isErrorLead={isError}
                router={router}
                lead={lead}
            />
        );
    };

    ComponentWithLeadFetching.displayName = `withLeadFetching(${displayName})`;

    return ComponentWithLeadFetching;
}
