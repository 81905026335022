import React, { useState, useEffect } from 'react';
import { Icon, colors } from 'spoton-lib';
import { motion, AnimatePresence } from 'framer-motion';

import { CheckSolidIcon } from 'assets';
import { RadioInput } from 'components';
import { slugify } from 'utils';

import { IPropTypes } from './CategoryTile.types';
import styles from './CategoryTile.module.scss';

export function CategoryTile(props: IPropTypes): JSX.Element {
    const { category, selectedCategory, onCategoryClick } = props;

    const { id, icon, name, subCategories } = category;

    const [isSubCategoryExpanded, setIsSubCategoryExpanded] = useState(false);

    const isAnySubCategorySelected = () =>
        subCategories.some((category) => category.id === selectedCategory);

    const computeClasses = () => {
        const shouldTileBeActive =
            selectedCategory === id || isAnySubCategorySelected();
        return `${shouldTileBeActive && styles.CategoryTile___active}`;
    };

    const computeExpandedClasses = () =>
        `${isSubCategoryExpanded && styles.CategoryTile___expanded}`;

    useEffect(() => {
        let shouldExpand = false;
        if (subCategories.length > 0) {
            shouldExpand = isAnySubCategorySelected();
        }
        setIsSubCategoryExpanded(shouldExpand);
    }, [subCategories, selectedCategory, setIsSubCategoryExpanded]);

    const renderSubCategories = () => {
        return (
            <AnimatePresence initial={false}>
                {isSubCategoryExpanded && (
                    <motion.div
                        id={`${slugify(name)}-collapse`}
                        role="radiogroup"
                        aria-labelledby={`category-${slugify(name)}`}
                        aria-hidden={!isSubCategoryExpanded}
                        className={styles.SubCategory_list}
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: {
                                opacity: 1,
                                height: 'auto',
                                marginTop: '0.5rem',
                                marginBottom: '1.5rem',
                            },
                            collapsed: {
                                opacity: 0,
                                height: 0,
                                marginTop: 0,
                                marginBottom: 0,
                            },
                        }}
                        transition={{
                            duration: 0.4,
                            ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                    >
                        {subCategories.map(({ name, id }) => (
                            <RadioInput
                                key={id}
                                label={name}
                                isChecked={selectedCategory === id}
                                name={`${category.name}-subcategories`}
                                value={id}
                                onChange={(categoryId) => {
                                    onCategoryClick(categoryId as number);
                                }}
                            />
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        );
    };

    const renderTile = () => {
        const isSelected = selectedCategory === category.id;
        return (
            <button
                className={styles.CategoryTile_header}
                role="radio"
                aria-checked={isSelected}
                onClick={() => {
                    onCategoryClick(id);
                }}
            >
                <Icon
                    aria-role="presentation"
                    size={32}
                    name={icon ? icon : 'CategoryIcon'}
                    alt={`${name} icon`}
                    className={styles.CategoryTile_icon}
                    color={colors.primary50}
                />
                <span className={styles.CategoryTile_title}>{name}</span>
                {isSelected && (
                    <CheckSolidIcon className={styles.CategoryTile_checkIcon} />
                )}
            </button>
        );
    };

    const renderExpandableTile = () => {
        return (
            <div>
                <button
                    className={styles.CategoryTile_header}
                    aria-controls={`${slugify(name)}-collapse`}
                    aria-expanded={isSubCategoryExpanded}
                    onClick={() => {
                        setIsSubCategoryExpanded(!isSubCategoryExpanded);
                    }}
                >
                    <Icon
                        aria-role="presentation"
                        size={32}
                        name={icon ? icon : 'CategoryIcon'}
                        alt={`${name} icon`}
                        className={styles.CategoryTile_icon}
                        color={colors.primary50}
                    />
                    <h6
                        id={`category-${slugify(name)}`}
                        className={styles.CategoryTile_title}
                    >
                        {name}
                    </h6>
                </button>
                {renderSubCategories()}
            </div>
        );
    };

    return (
        <li
            className={`${
                styles.CategoryTile
            } ${computeClasses()} ${computeExpandedClasses()}`}
        >
            {subCategories.length > 0 ? renderExpandableTile() : renderTile()}
        </li>
    );
}

export default CategoryTile;
