import React from 'react';
import { Icon, colors } from 'spoton-lib';

import { CheckSolidIcon } from 'assets';

import { IPropTypes } from './OptionTile.types';
import styles from './OptionTile.module.scss';

export function OptionTile(props: IPropTypes): JSX.Element {
    const { option, isSelected, onOptionClick } = props;

    const renderIcon = () => {
        if (option.icon && typeof option.icon !== 'string') {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const OptionIcon = option.icon as React.ElementType;
            return <OptionIcon />;
        }

        return (
            <Icon
                aria-role="presentation"
                size={32}
                name={option.icon ? (option.icon as string) : 'ServicesIcon'}
                alt={`${option.value} icon`}
                className={styles.OptionTile_icon}
                color={colors.primary50}
            />
        );
    };

    return (
        <li
            className={`${styles.OptionTile} ${
                isSelected ? styles.OptionTile___active : ''
            }`}
        >
            <button
                className={styles.OptionTile_header}
                role="radio"
                aria-checked={isSelected}
                onClick={() => {
                    onOptionClick(option.id);
                }}
            >
                {renderIcon()}
                <span className={styles.OptionTile_title}>{option.value}</span>
                {isSelected && (
                    <CheckSolidIcon className={styles.OptionTile_checkIcon} />
                )}
            </button>
        </li>
    );
}

export default OptionTile;
