import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './SiteStylingPage.module.scss';

export function LoadingState(): JSX.Element {
    return (
        <div className={styles.LoadingState} data-testid="palette-loaders">
            <ContentLoader speed={1} viewBox="0 0 462 462">
                <rect x="0" y="0" rx="4" ry="4" width="138" height="77" />
                <rect x="162" y="0" rx="4" ry="4" width="138" height="77" />
                <rect x="324" y="0" rx="4" ry="4" width="138" height="77" />
                <rect x="0" y="101" rx="4" ry="4" width="138" height="77" />
                <rect x="162" y="101" rx="4" ry="4" width="138" height="77" />
                <rect x="324" y="101" rx="4" ry="4" width="138" height="77" />
                <rect x="0" y="202" rx="4" ry="4" width="138" height="77" />
                <rect x="162" y="202" rx="4" ry="4" width="138" height="77" />
                <rect x="324" y="202" rx="4" ry="4" width="138" height="77" />
            </ContentLoader>
        </div>
    );
}

export default LoadingState;
