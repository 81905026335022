import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Input } from 'spoton-lib';

import { useScript, getConfigVar } from 'utils';

import { IPropTypes } from './GoogleAutocomplete.types';
import styles from './GoogleAutocomplete.module.scss';
import './AutocompleteDropdown.scss';

let autoComplete: google.maps.places.Autocomplete;

export function GoogleAutocomplete(props: IPropTypes): JSX.Element {
    const { type, onChange, label, name, id, initialValue, getQuery } = props;

    const [query, setQuery] = useState<string | undefined>(undefined);
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    const status = useScript(
        `https://maps.googleapis.com/maps/api/js?key=${getConfigVar(
            'REACT_APP_PLACES_API_KEY',
        )}&libraries=places`,
    );

    const handleQuery = (event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
        if (getQuery) {
            getQuery(event.target.value);
        }
    };

    const handlePlaceSelect = () => {
        const place = autoComplete.getPlace();
        const placeQuery = place
            ? place?.formatted_address || place?.name
            : undefined;
        setQuery(placeQuery);
        onChange(place);
    };

    const setAutoComplete = () => {
        autoComplete = new google.maps.places.Autocomplete(
            autoCompleteRef.current as HTMLInputElement,
            {
                types: [type],
                componentRestrictions: { country: 'us' },
            },
        );
        autoComplete.setFields([
            'formatted_address',
            'formatted_phone_number',
            'geometry',
            'name',
            'place_id',
            'photos',
            'types',
        ]);
        autoComplete.addListener('place_changed', () => handlePlaceSelect());
    };

    useEffect(() => {
        if (initialValue) {
            setQuery(initialValue);
        }

        if ((window as any).google && status === 'ready') {
            setAutoComplete();
        }
    }, [status]);

    return (
        <div className={styles.GoogleAutocomplete}>
            <Input
                id={id}
                data-testid="autocomplete"
                inputRef={autoCompleteRef}
                onChange={handleQuery}
                value={query}
                name={name}
                label={label}
            />
        </div>
    );
}

export default GoogleAutocomplete;
