import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EBookCallStatus, IBookCall } from './bookCall.types';

export const initialState: IBookCall = {
    status: EBookCallStatus.idle,
};

export const bookCallSlice = createSlice({
    name: 'bookCall',
    initialState,
    reducers: {
        updateBookCallStatus: (
            state,
            action: PayloadAction<EBookCallStatus>,
        ) => {
            state.status = action.payload;
        },
    },
});

const { actions, reducer } = bookCallSlice;

export const { updateBookCallStatus } = actions;

export default reducer;
