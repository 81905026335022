import React, { useState } from 'react';

import { MobileIcon, DesktopIcon, TabletIcon, FullScreenIcon } from 'assets';

import { EBreakpoint, IPropTypes } from './ResponsivePreview.types';
import styles from './ResponsivePreview.module.scss';

export function ResponsivePreview(props: IPropTypes): JSX.Element {
    const { src } = props;

    const [activeBreakpoint, setActiveBreakpoint] = useState(
        EBreakpoint.desktop,
    );

    const getResponsiveClasses = (): string => {
        const responsiveClass = styles[`Preview___${activeBreakpoint}`];

        return `${styles.Preview} ${responsiveClass}`;
    };

    const renderBreakpoint = (breakpoint: EBreakpoint): JSX.Element => {
        const iconSet = {
            mobile: MobileIcon,
            tablet: TabletIcon,
            desktop: DesktopIcon,
        };

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const BreakpointIcon = iconSet[breakpoint];

        return (
            <button
                className={styles.Breakpoint}
                aria-labelledby={breakpoint}
                onClick={() => {
                    setActiveBreakpoint(breakpoint);
                }}
            >
                <span id={breakpoint} hidden>
                    {breakpoint}
                </span>
                <BreakpointIcon
                    className={`${styles.Breakpoint_icon} ${
                        breakpoint === activeBreakpoint
                            ? styles.Breakpoint_icon___active
                            : ''
                    }`}
                    aria-hidden="true"
                    focusable="false"
                />
            </button>
        );
    };

    return (
        <div
            className={styles.ResponsivePreview}
            data-testid="responsive-preview"
        >
            <div className={styles.ResponsivePreview_header}>
                <div role="group" className={styles.Breakpoints}>
                    {renderBreakpoint(EBreakpoint.mobile)}
                    {renderBreakpoint(EBreakpoint.tablet)}
                    {renderBreakpoint(EBreakpoint.desktop)}
                </div>
                <FullScreenIcon
                    className={styles.FullScreen_icon}
                    onClick={() => {
                        window.open(src, '_blank', 'noopener,noreferrer');
                    }}
                />
            </div>
            <div className={styles.ResponsivePreview_content}>
                <div className={getResponsiveClasses()}>
                    <div className={styles.BrowserBar}>
                        <div className={styles.BrowserBar_dot}></div>
                        <div className={styles.BrowserBar_dot}></div>
                        <div className={styles.BrowserBar_dot}></div>
                    </div>
                    <iframe
                        src={src}
                        title="styled site preview"
                        className={styles.Preview_frame}
                        frameBorder="0"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default ResponsivePreview;
