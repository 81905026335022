/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars */
import { configureStore, EnhancedStore, AnyAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { leadApi } from 'services/lead';
import { categoryApi } from 'services/category';
import { serviceApi } from 'services/service';
import { photosApi } from 'services/photos';
import { stylesApi } from 'services/styles';

import * as middleware from './middleware';
import rootReducer from './rootReducer';

export default function configureAppStore(
    preloadedState: any,
): EnhancedStore<any, AnyAction> {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                // .prepend(middleware.logger)
                .concat(leadApi.middleware)
                .concat(serviceApi.middleware)
                .concat(categoryApi.middleware)
                .concat(photosApi.middleware)
                .concat(stylesApi.middleware),
        preloadedState,
    });

    setupListeners(store.dispatch);

    // hot reload on any non production ENV
    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('./rootReducer', () => {
            import('./rootReducer')
                .then((reducer) => {
                    store.replaceReducer(reducer.default);
                })
                .catch((e: Error) => {
                    console.error(
                        `[Error] Could not load reducers for hot reloading`,
                        e,
                    );
                });
        });
    }

    return store;
}
