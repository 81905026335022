import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Dropdown, Icon } from 'spoton-lib';

import { FormikInput } from 'components/FormikInput';

import { IPropTypes, ITldOption } from './DomainForm.types';
import styles from './DomainForm.module.scss';

export function DomainForm(props: IPropTypes): JSX.Element {
    const {
        tldList,
        defaultTld,
        isLoading,
        validationSchema,
        initialValues,
        label,
        buttonText,
        isReadOnly = false,
        onSubmit,
    } = props;

    const tldOptions = useMemo(
        () =>
            tldList.map((tld) => {
                return { value: tld, label: `.${tld}` };
            }),
        [tldList],
    );

    const [tld, setTld] = useState<ITldOption>(
        defaultTld || { value: 'com', label: '.com' },
    );

    const handleDomainSubmit = (formData: { domain: string }) => {
        if (tld && formData.domain) {
            onSubmit(formData.domain, tld.value);
        }
    };

    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={initialValues || { domain: '' }}
            validationSchema={validationSchema}
            onSubmit={handleDomainSubmit}
        >
            {({ isValid }) => (
                <Form className={styles.DomainForm}>
                    <div className={styles.DomainForm_inputSection}>
                        <FormikInput
                            id="domain"
                            name="domain"
                            label={label}
                            className={styles.DomainForm_textInput}
                            icon={
                                <Icon
                                    alt="Search"
                                    name="SearchIcon"
                                    size={18}
                                />
                            }
                            data-testid="domain-input"
                            disabled={isReadOnly}
                        />
                        <Dropdown
                            styles={{
                                control: () => ({
                                    width: '7.5rem',
                                    marginLeft: '1rem',
                                }),
                            }}
                            value={tld}
                            options={tldOptions}
                            onChange={setTld}
                            data-testid="tld-dropdown"
                            isDisabled={isReadOnly}
                        />
                    </div>
                    <Button
                        type="submit"
                        variant="secondary"
                        data-testid="domain-submit"
                        disabled={!isValid || isLoading}
                        isLoading={isLoading}
                    >
                        {buttonText}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

export default DomainForm;
