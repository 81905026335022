import React from 'react';

import { LightOnIcon } from 'assets';

import { IPropTypes } from './AboutUs.types';
import styles from './AboutUs.module.scss';

export function AboutUs(props: IPropTypes): JSX.Element {
    const { title, description } = props;
    return (
        <div className={styles.Container}>
            <div className={styles.Content}>
                <h5 className={styles.Content_title}>{title}</h5>
                <p className={styles.Content_description}>{description}</p>
            </div>
            <div className={styles.Disclaimer}>
                <LightOnIcon className={styles.Disclaimer_svg} />
                <span className={styles.Disclaimer_content}>
                    This is just an example of generated content we will use
                    based on the information you have provided.
                </span>
            </div>
        </div>
    );
}

export default AboutUs;
