import { useMemo, useState, useRef, useEffect, MutableRefObject } from 'react';
import {
    useParams,
    useLocation,
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import queryString from 'query-string';

// TODO: add proper typings
export function useRouter(): any {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: history.push,
            replace: history.replace,
            goBack: history.goBack,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search), // Convert string to object
                ...params,
            },
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            match,
            location,
            history,
        };
    }, [params, match, location, history]);
}

export function useHover<T>(): [MutableRefObject<T>, boolean] {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [value, setValue] = useState<boolean>(false);
    const ref: any = useRef<T | null>(null);
    const handleMouseOver = (): void => setValue(true);
    const handleMouseOut = (): void => setValue(false);
    useEffect(
        () => {
            const node: any = ref.current;
            if (node) {
                node.addEventListener('mouseover', handleMouseOver);
                node.addEventListener('mouseout', handleMouseOut);
                return () => {
                    node.removeEventListener('mouseover', handleMouseOver);
                    node.removeEventListener('mouseout', handleMouseOut);
                };
            }
        },
        [ref.current], // Recall only if ref changes
    );
    return [ref, value];
}

export const useMedia = <T>(
    queries: string[],
    values: T[],
    defaultValue: T,
): T => {
    // Array containing a media query list for each query
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));
    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex((mql) => mql?.matches);
        // Return related value or defaultValue if none
        return values?.[index] || defaultValue;
    };
    // State and setter for matched value
    const [value, setValue] = useState<T>(getValue);
    useEffect(
        () => {
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);
            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach((mql) => mql?.addListener(handler));
            // Remove listeners on cleanup
            return () =>
                mediaQueryLists.forEach((mql) => mql?.removeListener(handler));
        },
        [], // Empty array ensures effect is only run on mount and unmount
    );
    return value;
};

export const useScript = (src: string): string => {
    // Keep track of script status ("idle", "loading", "ready", "error")
    const [status, setStatus] = useState<string>(src ? 'loading' : 'idle');

    useEffect(() => {
        // Allow falsy src value if waiting on other data needed for
        // constructing the script URL passed to this hook.
        if (!src) {
            setStatus('idle');
            return;
        }
        let script: HTMLScriptElement;
        // Fetch existing script element by src
        // It may have been added by another intance of this hook
        const scriptLookup: HTMLScriptElement | null = document.querySelector(
            `script[src="${src}"]`,
        );
        if (!scriptLookup) {
            // Create script
            script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.setAttribute('data-status', 'loading');
            // Add script to document body
            document.body.appendChild(script);
            // Store status in attribute on script
            // This can be read by other instances of this hook
            const setAttributeFromEvent = (event: Event) => {
                script.setAttribute(
                    'data-status',
                    event.type === 'load' ? 'ready' : 'error',
                );
            };
            script.addEventListener('load', setAttributeFromEvent);
            script.addEventListener('error', setAttributeFromEvent);
        } else {
            script = scriptLookup;
            // Grab existing script status from attribute and set to state.
            setStatus(script.getAttribute('data-status') || status);
        }
        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event: Event) => {
            setStatus(event.type === 'load' ? 'ready' : 'error');
        };
        // Add event listeners
        script.addEventListener('load', setStateFromEvent);
        script.addEventListener('error', setStateFromEvent);
        // Remove event listeners on cleanup
        return () => {
            if (script) {
                script.removeEventListener('load', setStateFromEvent);
                script.removeEventListener('error', setStateFromEvent);
            }
        };
    }, [src]);

    return status;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLocalStorage = <T>(key: string, initialValue?: T) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            // console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: T | ((val: T) => T)) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            // console.log(error);
        }
    };

    return [storedValue, setValue] as const;
};

export const useTldList = (): string[] => {
    const [tldList, setTldList] = useState<string[]>([]);

    useEffect(() => {
        fetch('https://data.iana.org/TLD/tlds-alpha-by-domain.txt')
            .then((response) => response.text()) // get response text
            .then((data) => {
                // split the text into an array of TLDs
                const tlds = data.trim().toLowerCase().split('\n').slice(1);

                // set the TLDs array
                setTldList(tlds);
            })
            .catch((error) => console.error(error));
    }, []);

    return tldList;
};
