import React, { useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { Button } from 'spoton-lib';

import { FormikInput } from 'components';
import { IAboutBusiness } from 'types';
import { IPropTypes } from './AboutBusinessForm.types';
import styles from './AboutBusinessForm.module.scss';

function FetchFormValidationComponent({
    getFormValidation,
}: {
    getFormValidation: (state: boolean) => void;
}) {
    const { values, errors, isValid, touched } =
        useFormikContext<IAboutBusiness>();
    useEffect(() => {
        let isReadyToShowPreview = false;
        const isFormValuesFilled = Object.values(values).every((val) =>
            Boolean(val),
        );

        if (isFormValuesFilled) {
            isReadyToShowPreview =
                isValid && Object.values(errors).every((errVal) => !errVal);
        } else {
            isReadyToShowPreview =
                isValid &&
                Object.values(touched).some((val) => Boolean(val)) &&
                Object.values(errors).every((errVal) => !errVal);
        }

        getFormValidation(isReadyToShowPreview);
    }, [isValid, errors]);

    return null;
}

export function AboutBusinessForm(props: IPropTypes): JSX.Element {
    const {
        initialValues,
        validationSchema,
        isLoading,
        onSubmit,
        onBack,
        getFormValidationState,
    } = props;

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
            onSubmit={onSubmit}
        >
            {({ isValid }) => (
                <Form className={styles.AboutBusinessForm}>
                    <FetchFormValidationComponent
                        getFormValidation={getFormValidationState}
                    />
                    <div className={styles.AboutBusinessForm_form}>
                        <FormikInput
                            id="businessStart"
                            name="businessStart"
                            label="When did your business start?"
                        />
                        <FormikInput
                            id="businessDescription"
                            name="businessDescription"
                            label="Explain your business in a few words"
                        />
                    </div>
                    <div className={styles.AboutBusinessForm_actions}>
                        <Button
                            type="button"
                            variant="secondary"
                            className={styles.Button}
                            onClick={onBack}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            className={styles.Button}
                            isLoading={isLoading}
                            disabled={!isValid || isLoading}
                        >
                            Continue
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default AboutBusinessForm;
