import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDemoLink } from './demolink.types';

export const initialState: IDemoLink = {
    demoLink: '',
};

export const demolinkSlice = createSlice({
    name: 'demolink',
    initialState,
    reducers: {
        setDemoLink: (state, action: PayloadAction<string>) => {
            state.demoLink = action.payload;
        },
    },
});

const { actions, reducer } = demolinkSlice;

export const { setDemoLink } = actions;

export default reducer;
