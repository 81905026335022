import React from 'react';
import { WordLogo } from 'spoton-lib';

import styles from './Navbar.module.scss';
import { IPropTypes } from './Navbar.types';

export function Navbar(props: IPropTypes): JSX.Element {
    const { center, right } = props;

    return (
        <div className={styles.Navbar}>
            <div className={styles.Navbar_left}>
                <WordLogo />
            </div>

            <div className={styles.Navbar_center}>{center}</div>

            <div className={styles.Navbar_right}>{right}</div>
        </div>
    );
}

export default Navbar;
