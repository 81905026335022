import React, { Fragment, useState, useEffect } from 'react';
import { Button, Banner } from 'spoton-lib';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import {
    withLeadFetching,
    getPreviousStep,
    getNextStep,
    useLocalStorage,
    getConfigVar,
} from 'utils';
import {
    EDemoSteps,
    DEMO_STEPS,
    IFeaturedImage,
    IFeaturedImageUpdatePayload,
    EFeaturedImageSource,
    IUnsplashApiResponse,
} from 'types';

import {
    useUpdateFeaturedImagesMutation,
    useLazyGetPhotosQuery,
} from 'services';

import {
    Section,
    StepperIllustration,
    ImagePicker,
    PageLayout,
} from 'components';
import { PhotoLibrary } from 'assets';
import { setCurrentStep } from 'store';

import { LoadingState } from './LoadingState.component';
import { IPropTypes } from './ImagePickerPage.types';
import styles from './ImagePickerPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.featuredImages];

export function ImagePickerPage(props: IPropTypes): JSX.Element {
    const dispatch = useDispatch();
    const { lead, router, isLoadingLead, isErrorLead } = props;

    const [selectedImages, setSelectedImages] = useState<IFeaturedImage[]>([]);
    const [isSelectedLimit, setIsSelectedLimit] = useState<boolean>(false);

    const [leadIdLocal, setleadIdLocal] = useLocalStorage<string>('leadId');
    const [, setPlaceIdLocal] = useLocalStorage<string>('placeId');
    const [imagesLocal, setImagesLocal] =
        useLocalStorage<IFeaturedImage[]>('featuredImages');

    const [
        updateFeaturedImages,
        { isSuccess, isLoading, isError: isErrorUpdate },
    ] = useUpdateFeaturedImagesMutation();

    const [triggerGoogleQuery, resultGoogle] = useLazyGetPhotosQuery();
    const [imagesGoogle, setImagesGoogle] = useState<IFeaturedImage[] | []>([]);
    const [errorGoogle, setErrorGoogle] = useState<string | null>(null);

    const [imagesStock, setImagesStock] = useState<IFeaturedImage[] | []>([]);
    const [errorStock, setErrorStock] = useState<string | null>(null);

    useEffect(() => {
        if (resultGoogle.data) {
            setErrorGoogle(null);
            let googleImages = resultGoogle.data.result.photos?.map((photo) => {
                return {
                    source: EFeaturedImageSource.google,
                    url: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${
                        photo.photoReference
                    }&key=${getConfigVar('REACT_APP_PLACES_API_KEY')}`,
                };
            });

            setleadIdLocal(lead.id);
            setPlaceIdLocal(String(lead.googlePlacesId));

            if (googleImages && lead.featuredImages) {
                googleImages = googleImages.filter(
                    (image) =>
                        !lead.featuredImages.some(
                            (selectedImage) => selectedImage.url === image.url,
                        ),
                );
            }

            if (googleImages) setImagesLocal(googleImages);

            setImagesGoogle(googleImages);
        } else if (resultGoogle.isError) {
            console.error(resultGoogle.error);
            setErrorGoogle('Something went wrong');
        }
    }, [resultGoogle]);

    useEffect(() => {
        if (lead?.id && !isLoadingLead && !isErrorLead) {
            dispatch(setCurrentStep(EDemoSteps.featuredImages));
            setSelectedImages(lead.featuredImages);

            // fetch stock images based on the category
            if (lead.masterWebsiteUrl) {
                axios(
                    `${getConfigVar('REACT_APP_API_URL')}/category/${
                        lead.category
                    }/stock-images`,
                )
                    .then((resultStock: IUnsplashApiResponse) => {
                        setErrorStock(null);
                        let stockImages = resultStock.data.results.map(
                            (photo) => {
                                return {
                                    source: EFeaturedImageSource.stock,
                                    url: photo.urls.regular,
                                };
                            },
                        );

                        if (stockImages && lead.featuredImages) {
                            stockImages = stockImages.filter(
                                (image) =>
                                    !lead.featuredImages.some(
                                        (selectedImage) =>
                                            selectedImage.url.split('?')[0] ===
                                            image.url.split('?')[0],
                                    ),
                            );
                        }

                        setImagesStock(stockImages);
                    })
                    .catch((error) => {
                        console.error(error);
                        setErrorStock(error.message);
                    });
            }

            // Images from google place lookup
            if (leadIdLocal === lead.id && imagesLocal) {
                if (imagesLocal && lead.featuredImages) {
                    const imagesLocalFiltered = imagesLocal.filter(
                        (image) =>
                            !lead.featuredImages.some(
                                (selectedImage) =>
                                    selectedImage.url === image.url,
                            ),
                    );
                    setImagesGoogle(imagesLocalFiltered);
                } else {
                    setImagesGoogle(imagesLocal);
                }
            } else {
                // fetch images using the place_id
                lead.googlePlacesId && triggerGoogleQuery(lead.googlePlacesId);
            }
        }
    }, [lead]);

    const handleContinue = () => {
        const payload: IFeaturedImageUpdatePayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.featuredImages,
            featuredImages: selectedImages,
        };

        updateFeaturedImages(payload);
    };

    const handleSelectImage = (selectedImage: IFeaturedImage) => {
        if (selectedImages.every((image) => image.url !== selectedImage.url)) {
            selectedImages.length < 5
                ? setSelectedImages([...selectedImages, selectedImage])
                : setIsSelectedLimit(true);
        } else {
            if (selectedImages.length > 4) setIsSelectedLimit(false);
            setSelectedImages(
                selectedImages.filter(
                    (image) => image.url !== selectedImage.url,
                ),
            );
        }
    };

    const handleGoBack = () => {
        if (lead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getPreviousStep(EDemoSteps.featuredImages)].url
            }`;
            router.push(redirectUrl);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getNextStep(EDemoSteps.featuredImages)].url
            }`;
            router.push(redirectUrl);
        }
    }, [isSuccess]);

    const renderFooterActions = () => {
        return (
            <footer className={styles.ImagePickerPage_footer}>
                <Button
                    variant="secondary"
                    onClick={handleGoBack}
                    data-testid="cancelButton"
                    className={styles.Button}
                    disabled={isLoading}
                >
                    Back
                </Button>
                <Button
                    variant="primary"
                    onClick={handleContinue}
                    data-testid="continueButton"
                    className={styles.Button}
                    isLoading={isLoading}
                    disabled={selectedImages.length > 5}
                >
                    Continue
                </Button>
            </footer>
        );
    };

    const renderIllustration = () => {
        return (
            <StepperIllustration
                hasIcon
                title="Great photos make a great website"
                text="Your professional designer will ensure you are using the best images for your business. Images included here are for demo purposes."
                className={styles.Illustration_wrapper}
            >
                <PhotoLibrary className={styles.Illustration} />
            </StepperIllustration>
        );
    };

    return (
        <div data-testid="featured-images" className={styles.ImagePickerPage}>
            <PageLayout
                hasError={
                    isErrorUpdate ||
                    isErrorLead ||
                    (Boolean(errorGoogle) && Boolean(errorStock))
                }
                illustration={renderIllustration()}
            >
                <Fragment>
                    {isSelectedLimit && (
                        <Banner
                            className={styles.ImagePickerPage_banner}
                            message="You have chosen 5 pictures. Deselect one or more to replace your picks"
                            variant="informative"
                        />
                    )}
                    <Section
                        title="Choose up to 5 pictures"
                        className={styles.ImagePickerPage_section}
                    >
                        {isLoadingLead ||
                        resultGoogle.isLoading ||
                        isLoading ? (
                            <LoadingState />
                        ) : (
                            <div
                                className={styles.ImagePicker}
                                data-testid="image-picker-section"
                            >
                                <div className={styles.ImagePicker_wrapper}>
                                    {!isErrorLead && imagesGoogle && imagesStock
                                        ? [
                                              ...lead.featuredImages,
                                              ...imagesGoogle,
                                              ...imagesStock,
                                          ].map((image: IFeaturedImage) => (
                                              <ImagePicker
                                                  key={image.url}
                                                  image={image}
                                                  isSelected={selectedImages.some(
                                                      ({ url }) =>
                                                          image.url === url,
                                                  )}
                                                  onClick={() => {
                                                      handleSelectImage(image);
                                                  }}
                                              />
                                          ))
                                        : null}
                                </div>
                                {renderFooterActions()}
                            </div>
                        )}
                    </Section>
                </Fragment>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, ImagePickerPage);
