import React, { useState } from 'react';
import { Button, Modal } from 'spoton-lib';
import { useSelector } from 'react-redux';

import { demolinkSelectors } from 'store';
import { ShareDemoModal } from 'components';
import { useGetLeadQuery } from 'services';
import { getConfigVar } from 'utils';

import { IPropTypes } from './PreviewActions.types';
import styles from './PreviewActions.module.scss';

const websiteBaseUrl = {
    qa: 'https://qa-website.spoton.com',
    prod: 'https://website.spoton.com',
};

export function PreviewActions(props: IPropTypes): JSX.Element {
    const { leadId } = props;
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const url = useSelector(demolinkSelectors.getDemoLink);

    const { data: lead } = useGetLeadQuery(leadId || '', { skip: !leadId });

    const handleRedirectToEditor = () => {
        const isProd = !!getConfigVar('REACT_APP_IS_PROD');
        const baseUrl = websiteBaseUrl[isProd ? 'prod' : 'qa'];

        window.open(`${baseUrl}/${lead?.websiteSlug}/edit`);
    };

    return (
        <div data-testid="preview-actions" className={styles.PreviewActions}>
            <Button
                variant="secondary"
                onClick={() => setShareModalOpen(true)}
                className={styles.PreviewActions_share}
            >
                Share Demo
            </Button>
            <Button
                onClick={handleRedirectToEditor}
                className={styles.PreviewActions_edit}
            >
                Edit Your Website
            </Button>

            <Modal
                isOpen={isShareModalOpen}
                onRequestClose={() => setShareModalOpen(false)}
                className={styles.ShareDemoModal}
                overlayClassName={styles.Overlay}
                testId="share-modal"
                ariaHideApp={false}
            >
                <ShareDemoModal
                    link={url}
                    onCopyLinkClick={() => navigator.clipboard.writeText(url)}
                    onClose={() => setShareModalOpen(false)}
                />
            </Modal>
        </div>
    );
}

export default PreviewActions;
