import React from 'react';
import { Input } from 'spoton-lib';
import { Field, FieldProps } from 'formik';

import { IPropTypes } from './FormikInput.types';

export function FormikInput({
    id,
    label,
    helperText,
    placeholder,
    className,
    icon,
    disabled,
}: IPropTypes): JSX.Element {
    return (
        <Field name={id}>
            {({ meta, field }: FieldProps) => {
                /**
                 * A field is valid if one of the following happens:
                 * - the initial value is the same as the current value (is pristine) and has not been focused on
                 * - OR it has been focused on but there are no errors after the onBlur event
                 */
                const isValid =
                    (meta.initialValue === meta.value && !meta.touched) ||
                    (meta.touched && !meta.error) ||
                    (!meta.touched && !meta.error);

                return (
                    <Input
                        id={id}
                        data-testid={id}
                        label={label}
                        placeholder={placeholder}
                        secondaryCondition={isValid ? helperText : meta.error}
                        isValid={isValid}
                        className={className}
                        {...field}
                        isMultilineSecondaryCondition
                        icon={icon}
                        disabled={disabled}
                    />
                );
            }}
        </Field>
    );
}

export default FormikInput;
