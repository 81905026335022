import React, { useEffect } from 'react';
import { ToastContainer, showToast } from 'spoton-lib';

import { useRouter } from 'utils';

import styles from './ConnectedDomain.module.scss';

export function ConnectedDomain(): JSX.Element {
    const router = useRouter();

    const getToastContent = (): string => {
        const domainName = router.query.domainName;
        return `${domainName} has been connected`;
    };

    useEffect(() => {
        showToast({
            content: getToastContent(),
            variant: 'success',
            containerId: 'connected-toast',
        });
    }, []);

    return (
        <div className={styles.ConnectedDomain}>
            <h3 className={styles.ConnectedDomain_title}>It’s yours!</h3>
            <h5 className={styles.ConnectedDomain_subtitle}>
                We have connected your website successfully!
            </h5>
            <div className={styles.Toast}>
                <ToastContainer
                    containerId="connected-toast"
                    stylePosition="relative"
                    className={styles.Toast_content}
                />
            </div>
        </div>
    );
}

export default ConnectedDomain;
