import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reduxConfig';

const bookCallState = (state: RootState) => state.bookCall;

export const getBookCall = createSelector(
    bookCallState,
    (state) => state.status,
);
