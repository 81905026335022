import React, { useEffect } from 'react';
import { Button } from 'spoton-lib';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import {
    PageLayout,
    Section,
    ExistingDomain,
    NewDomain,
    ConnectedDomain,
} from 'components';
import { DEMO_STEPS, EDemoSteps } from 'types';
import { getNextStep, getPreviousStep, withLeadFetching } from 'utils';
import { WebDomain } from 'assets';
import { setCurrentStep } from 'store';
import { useUpdateLeadLastStepMutation } from 'services';

import { IPropTypes, IOptionPropTypes } from './DomainSelectionPage.types';
import styles from './DomainSelectionPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.domainPreference];

function SelectionOption(props: IOptionPropTypes): JSX.Element {
    const { buttonText, label, onClick } = props;
    return (
        <div className={styles.SelectionOption}>
            <Button
                variant="primary"
                className={styles.Button}
                isLoading={false}
                disabled={false}
                onClick={onClick}
            >
                {buttonText}
            </Button>
            <p className={styles.SelectionOption_label}>{label}</p>
        </div>
    );
}

function DomainSelectionPage(props: IPropTypes): JSX.Element {
    const { lead, router, isLoadingLead, isErrorLead } = props;

    const dispatch = useDispatch();

    const [updateLeadLastStep, { isSuccess: isSuccessUpdateLeadLastStep }] =
        useUpdateLeadLastStepMutation();

    useEffect(() => {
        if (!isLoadingLead && !isErrorLead) {
            dispatch(setCurrentStep(EDemoSteps.domainPreference));
        }
    }, [isLoadingLead]);

    useEffect(() => {
        if (isSuccessUpdateLeadLastStep && !isLoadingLead && !isErrorLead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getNextStep(EDemoSteps.domainPreference)].url
            }`;
            router.push(redirectUrl);
        }
    }, [isSuccessUpdateLeadLastStep, isLoadingLead]);

    const handleExistingDomainClick = (): void => {
        router.push(`${router.match.url}/existing`);
    };

    const handleNewDomainClick = (): void => {
        router.push(`${router.match.url}/new`);
    };

    const handleGoBack = () => {
        if (lead) {
            const redirectOptions: Record<string, string> = {
                domain: `/lead/${lead.id}${
                    DEMO_STEPS[getPreviousStep(EDemoSteps.domainPreference)].url
                }`,
                existing: `/lead/${lead.id}/domain`,
                new: `/lead/${lead.id}/domain`,
                connected: `/lead/${lead.id}/domain`,
            };

            const termination = router.location.pathname.split('/').pop();

            router.push(redirectOptions[termination]);
        }
    };

    const handleContinue = () => {
        if (lead) {
            updateLeadLastStep({
                leadId: lead.id,
                currentStep: EDemoSteps.domainPreference,
                lastStep: EDemoSteps.domainPreference,
            });
        }
    };

    const getBackButtonLabel = (): string => {
        const sectionTexts: Record<string, string> = {
            domain: 'Back to Edit',
            existing: 'Go Back',
            new: 'Go Back',
            connected: 'Go Back',
        };

        const termination = router.location.pathname.split('/').pop();

        return sectionTexts[termination];
    };

    const renderFooterActions = (): JSX.Element => {
        let continueButtonText = 'Continue without domain';

        if (router.location.pathname.includes('connected')) {
            continueButtonText = 'Get AI Website';
        }

        return (
            <div className={styles.DomainSelectionPage_footer}>
                <Button
                    variant="secondary"
                    className={styles.Button}
                    onClick={handleGoBack}
                >
                    {getBackButtonLabel()}
                </Button>
                <Button
                    variant="primary"
                    className={styles.Button}
                    disabled={isLoadingLead}
                    onClick={handleContinue}
                >
                    {continueButtonText}
                </Button>
            </div>
        );
    };

    const renderDomainMainPage = (): JSX.Element => {
        return (
            <div className={styles.DomainSelectionPageContent}>
                <div className={styles.DomainSelectionPage_options}>
                    <SelectionOption
                        buttonText="I already own a domain name"
                        label="Use this option the business already has an address they are using for an existing website or have registered previously."
                        onClick={handleExistingDomainClick}
                    />
                    <SelectionOption
                        buttonText="I need a new domain name"
                        label="Use this option if the business needs a new address that is available to be registered."
                        onClick={handleNewDomainClick}
                    />
                </div>
            </div>
        );
    };

    const getSectionTitle = (): string => {
        const sectionTitles: Record<string, string> = {
            domain: 'Pick your domain name',
            existing: 'Let us know your domain name',
            new: 'Get a new domain name',
        };
        const termination = router.location.pathname.split('/').pop();

        return sectionTitles[termination];
    };

    return (
        <div
            data-testid="domain-preference-page"
            className={styles.DomainSelectionPage}
        >
            <PageLayout
                hasError={isErrorLead}
                illustration={
                    <div className={styles.Illustration}>
                        <WebDomain />
                    </div>
                }
            >
                <Section
                    title={getSectionTitle()}
                    className={styles.DomainSelectionPage_section}
                >
                    <Switch>
                        <Route exact path={router.match.path}>
                            {renderDomainMainPage()}
                        </Route>
                        <Route path={`${router.match.path}/new`}>
                            <NewDomain leadId={lead?.id} />
                        </Route>
                        <Route path={`${router.match.path}/existing`}>
                            <ExistingDomain leadId={lead?.id} />
                        </Route>
                        <Route path={`${router.match.path}/connected`}>
                            <ConnectedDomain />
                        </Route>
                    </Switch>
                    {renderFooterActions()}
                </Section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, DomainSelectionPage);
