import { rest } from 'msw';

import { tldList } from './domain.mock';

export const domainHandlers = [
    rest.get('*/TLD/tlds-alpha-by-domain.txt', (req, res, ctx) => {
        const response = tldList.join('\n');

        return res(ctx.text(response), ctx.delay(50));
    }),
];
