export enum EBreakpoint {
    mobile = 'mobile',
    tablet = 'tablet',
    desktop = 'desktop',
}

export interface IPropTypes {
    /** url to display */
    src: string;
}
