import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getConfigVar } from 'utils';
import { IGoogleApiResponse } from 'types';

export const photosApi = createApi({
    reducerPath: 'photos',
    tagTypes: ['Photos'],
    baseQuery: fetchBaseQuery({
        baseUrl: getConfigVar('REACT_APP_API_URL') as string,
    }),
    endpoints: (build) => ({
        getPhotos: build.query<IGoogleApiResponse, string>({
            query: (placeId) => `/google-places/${placeId}`,
            providesTags: ['Photos'],
        }),
    }),
});

export const { useLazyGetPhotosQuery } = photosApi;
