import { EDemoSteps } from './demoSteps.types';
import {
    IAboutBusiness,
    IBusinessInfo,
    IContactInfo,
    ILead,
} from './leads.types';
import { ILeadPage } from './pickPages.types';

export type IRequestPayload = {
    leadId: string;
    currentStep: EDemoSteps;
    lastStep?: EDemoSteps; // Set for specific steps only (AboutBusiness)
};

// create lead
export type ICreateLeadPayload = Partial<IRequestPayload> &
    IBusinessInfo & { featuredImages?: IFeaturedImagePayload[] };

export type ICreateLeadResponse = ILead;

export type IUpdateLeadLastStepResponse = ILead;

// update business info
export type IUpdateBusinessInfoPayload = IBusinessInfo &
    IRequestPayload &
    Partial<IAboutBusiness> & {
        featuredImages?: IFeaturedImagePayload[];
    };

export type IUpdateBusinessInfoResponse = IBusinessInfo &
    Partial<IAboutBusiness>;

// update about business info
export type IUpdateAboutBusinessInfoPayload = IAboutBusiness & IRequestPayload;

export type IUpdateAboutBusinessInfoResponse = ILead;

// update spoton services
export type IUpdateSpotonServicesPayload = {
    spotonServices: number[];
} & IRequestPayload;

export type IUpdateSpotonServicesResponse = {
    spotonServices: number[];
};

// update contact info
export type IUpdateContactInfoPayload = IContactInfo & IRequestPayload;

export type IUpdateContactInfoResponse = IContactInfo;

// update category
export type IUpdateCategoryPayload = {
    categoryId: number | null;
} & IRequestPayload;

export type IUpdateCategoryResponse = { categoryId: number };

// update services
export type IUpdateServicesPayload = {
    leadId: string;
    services: string[];
} & IRequestPayload;

export type IUpdateServicesResponse = ILead;

// update featured images
export enum EFeaturedImageSource {
    google = 'GOOGLE',
    stock = 'STOCK',
}

export interface IFeaturedImagePayload {
    url: string;
    source: EFeaturedImageSource;
}

export type IFeaturedImageResponse = {
    id: number;
    url: string;
    source: EFeaturedImageSource;
};

export type IFeaturedImageUpdatePayload = {
    featuredImages: IFeaturedImagePayload[];
} & IRequestPayload;

export type ICreateLeadPagesPayload = {
    name: string;
} & IRequestPayload;

export type ICreateLeadPagesResponse = { page: ILeadPage };

export type IUpdateLeadPagesPayload = {
    pageId: number;
    isShown: boolean;
} & IRequestPayload;

export type IUpdateLeadPagesResponse = { page: ILeadPage };

// update site styling
export type IUpdateSiteStylePayload = { style: string } & IRequestPayload;

export type IUpdateSiteStyleResponse = { style: string };

export type IUpdateBookCallPayload = {
    bookCall: string;
} & IRequestPayload;

export type IUpdateSiteLogoPayload = { image: FormData; leadId: string };
export type IUpdateSiteLogoResponse = ILead;

export type IUpdateLeadDomainNamePayload = {
    name: string;
    postfix: string;
    shouldValidate: boolean;
    leadId: string;
    currentStep: EDemoSteps;
    connect: boolean;
};
export type IUpdateLeadDomainNameResponse = ILead;

export type IUpdateGeneratedLeadPagesPayload = {
    leadId: string;
    currentStep: EDemoSteps;
};
export type IUpdateGeneratedLeadPagesResponse = ILead;
