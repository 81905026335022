import React, { useState, useEffect } from 'react';
import { Button } from 'spoton-lib';
import { useDispatch } from 'react-redux';

import { withLeadFetching, getPreviousStep, getNextStep } from 'utils';
import { EDemoSteps, DEMO_STEPS, IUpdateSpotonServicesPayload } from 'types';
import {
    useGetSpotonServicesQuery,
    useUpdateSpotonServicesMutation,
} from 'services';
import { setCurrentStep } from 'store';

import {
    Section,
    StepperIllustration,
    SpotonService,
    PageLayout,
} from 'components';
import { AddOns } from 'assets';

import { LoadingState } from './LoadingState.component';
import { IPropTypes } from './SpotonServicesPage.types';
import styles from './SpotonServicesPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.spotonServices];

export function SpotonServicesPage(props: IPropTypes): JSX.Element {
    const dispatch = useDispatch();
    const { lead, router, isLoadingLead, isErrorLead } = props;
    const [spotonServices, setSpotonServices] = useState<number[]>([]);
    const {
        data: services,
        isFetching,
        isLoading: isLoadingServices,
        isError: isErrorServices,
    } = useGetSpotonServicesQuery(router.query.id);

    useEffect(() => {
        if (!isLoadingLead && !isErrorLead) {
            setSpotonServices(lead.spotonServices || []);

            dispatch(setCurrentStep(EDemoSteps.spotonServices));
        }
    }, [isLoadingLead]);

    const [
        updateSpotonService,
        { isSuccess, isLoading, isError: isErrorUpdate },
    ] = useUpdateSpotonServicesMutation();

    const handleSpotonServicesUpdate = () => {
        const payload: IUpdateSpotonServicesPayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.spotonServices,
            spotonServices,
        };

        updateSpotonService(payload);
    };

    const handleServiceClick = (id: number) => {
        if (!spotonServices.includes(id)) {
            setSpotonServices([...spotonServices, id]);
        } else {
            setSpotonServices(
                spotonServices.filter((serviceId) => serviceId !== id),
            );
        }
    };

    const handleGoBack = (): void => {
        if (lead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getPreviousStep(EDemoSteps.spotonServices)].url
            }`;
            router.push(redirectUrl);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getNextStep(EDemoSteps.spotonServices)].url
            }`;
            router.push(redirectUrl);
        }
    }, [isSuccess]);

    const renderFooterActions = (): JSX.Element => {
        return (
            <footer className={styles.ServicesPageSection_footer}>
                <Button
                    variant="secondary"
                    onClick={handleGoBack}
                    data-testid="cancelButton"
                    className={styles.Button}
                    disabled={isLoading}
                >
                    Back
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSpotonServicesUpdate}
                    data-testid="continueButton"
                    className={styles.Button}
                    isLoading={isLoading}
                >
                    Continue
                </Button>
            </footer>
        );
    };

    return (
        <div data-testid="spoton-service-page" className={styles.ServicesPage}>
            <PageLayout
                hasError={isErrorServices || isErrorUpdate || isErrorLead}
                illustration={
                    <StepperIllustration
                        hasIcon
                        title="Take your business to the next level."
                        text="Your website integrates with other SpotOn products, providing a complete experience that will help your business grow."
                        className={styles.Illustration_wrapper}
                    >
                        <AddOns className={styles.Illustration} />
                    </StepperIllustration>
                }
            >
                <Section
                    className={styles.ServicesPage_section}
                    title="What would you like your website to do?"
                >
                    {isLoadingLead || isLoadingServices || isFetching ? (
                        <LoadingState />
                    ) : (
                        <div className={styles.ServicesPageSection}>
                            <div
                                role="group"
                                className={styles.ServicesPageSection_services}
                            >
                                {services &&
                                    services.map((service) => (
                                        <SpotonService
                                            key={service.id}
                                            service={service}
                                            isSelected={spotonServices.includes(
                                                service.id,
                                            )}
                                            onServiceSelect={handleServiceClick}
                                        />
                                    ))}
                            </div>
                            {renderFooterActions()}
                        </div>
                    )}
                </Section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, SpotonServicesPage);
