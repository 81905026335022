import React from 'react';

import { CheckSolidIcon } from 'assets';

import { IPropTypes } from './RadioInput.types';
import styles from './RadioInput.module.scss';

export function RadioInput(props: IPropTypes): JSX.Element {
    const {
        label,
        isChecked,
        name,
        value,
        onChange,
        isDisabled = false,
    } = props;

    return (
        <div className={styles.RadioInput}>
            <input
                id={label.toLowerCase().split(' ').join('_')}
                aria-checked={isChecked}
                type="radio"
                name={name}
                value={value}
                checked={isChecked}
                disabled={isDisabled}
                className={styles.RadioInput_input}
                onChange={() => onChange(value)}
            />

            <label
                className={styles.RadioInput_label}
                htmlFor={label.toLowerCase().split(' ').join('_')}
            >
                {label}
            </label>

            {isChecked && (
                <CheckSolidIcon className={styles.RadioInput_checkIcon} />
            )}
        </div>
    );
}

export default RadioInput;
