import * as Yup from 'yup';

// eslint-disable-next-line no-useless-escape
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

// TODO: need confirmation about length and phone number format
export const contactSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    email: Yup.string().email('Invalid email address'),
    phoneNumber: Yup.string().matches(phoneRegex, 'Invalid phone number'),
});

export const nameAddressPhoneSchema = Yup.object().shape({
    // TODO: confirm field length
    businessName: Yup.string()
        .min(2, 'Business Name must be 2 characters or more')
        .max(250, 'Business Name must be less than 250 characters')
        .required('Business Name is required'),
    address: Yup.string()
        .min(2, 'Address must be 2 characters or more')
        .max(250, 'Address must be less than 250 characters')
        .required('Address is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(phoneRegex, 'Invalid phone number'),
});

export const namePhoneSchema = Yup.object().shape({
    // TODO: confirm field length
    businessName: Yup.string()
        .min(2, 'Business Name must be 2 characters or more')
        .max(250, 'Business Name must be less than 250 characters')
        .required('Business Name is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(phoneRegex, 'Invalid phone number'),
});

export const aboutBusinessSchema = Yup.object().shape({
    businessDescription: Yup.string()
        .min(15, 'Business Description must be 15 characters or more')
        .max(250, 'Business Description must be less than 250 characters')
        .required('Business Description is required'),
    businessStart: Yup.string()
        .min(2, 'Business Start must be 2 characters or more')
        .required('Business Start is required'),
});

export const addServiceSchema = Yup.object().shape({
    addOption: Yup.string()
        .min(2, 'Service must be 2 characters or more')
        .ensure(),
});

export const domainSchema = Yup.object().shape({
    domain: Yup.string()
        .required('Domain name is required')
        .min(2, 'Domain name must be 2 characters or more'),
});

export const addPageSchema = Yup.object().shape({
    addOption: Yup.string().min(1, 'Too Short!').ensure(),
});
