import React from 'react';
import ContentLoader from 'react-content-loader';

export function LoadingState(): JSX.Element {
    return (
        <div
            style={{
                margin: '2rem',
                width: '50vw',
                height: '100vh',
            }}
            data-testid="images-loaders"
        >
            <ContentLoader viewBox="0 0 638 669">
                <rect x="186" y="23" rx="3" ry="3" width="162" height="6" />
                <rect x="76" y="56" rx="3" ry="3" width="394" height="6" />
                <rect x="102" y="74" rx="3" ry="3" width="342" height="6" />
                <rect x="15" y="129" rx="4" ry="4" width="150" height="94" />
                <rect x="15" y="264" rx="4" ry="4" width="150" height="94" />
                <rect x="198" y="129" rx="4" ry="4" width="150" height="94" />
                <rect x="198" y="264" rx="4" ry="4" width="150" height="94" />
                <rect x="381" y="129" rx="4" ry="4" width="150" height="94" />
                <rect x="381" y="264" rx="4" ry="4" width="150" height="94" />
            </ContentLoader>
        </div>
    );
}

export default LoadingState;
