export const slugify = (value: string, divider?: string): string => {
    if (!divider) divider = '-';

    return value.toLowerCase().split(' ').join(divider);
};

export const capitalize = (value: string): string => {
    return value
        .toLowerCase()
        .split(' ')
        .map((word) => word.substring(0, 1).toUpperCase() + word.substring(1))
        .join(' ');
};
