import { ILead, EDemoSteps } from 'types';

export const lead: ILead = {
    id: 'testId',
    businessName: '',
    phoneNumber: '',
    location: '',
    googlePlacesId: '0',
    bookCall: 'NOW',
    lastStep: EDemoSteps.businessInfo,
    spotonServices: [],
    featuredImages: [],
    category: 0,
    style: '',
    contact: {
        name: '',
        email: '',
        phoneNumber: '',
    },
    masterWebsiteUrl: '',
    businessDescription: '',
    businessStart: '',
    logoUrl: '',
    websiteId: '',
    websiteUrl: '',
    websiteSlug: '',
};

export const aboutLead = {
    text: 'Paris Las Vegas is a food merchant that specializes in serving high-quality burgers with fresh ingredients.',
};

export const leadEmptyState: ILead = { ...lead, id: '', googlePlacesId: '' };
