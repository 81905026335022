import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDemoSteps } from 'types';

import { IStepperState } from './stepper.types';

export const initialState: IStepperState = {
    currentStep: EDemoSteps.businessInfo,
};

export const stepperSlice = createSlice({
    name: 'stepper',
    initialState,
    reducers: {
        setCurrentStep: (state, action: PayloadAction<EDemoSteps>) => {
            state.currentStep = action.payload;
        },
    },
});

const { actions, reducer } = stepperSlice;

export const { setCurrentStep } = actions;

export default reducer;
