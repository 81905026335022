import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getConfigVar } from 'utils';
import { IPalette } from 'types';

export const stylesApi = createApi({
    reducerPath: 'styles',
    tagTypes: ['Styles'],
    baseQuery: fetchBaseQuery({
        baseUrl: getConfigVar('REACT_APP_API_URL') as string,
    }),
    endpoints: (build) => ({
        getStyles: build.query<IPalette[], string>({
            query: () => `/styles`,
            providesTags: ['Styles'],
        }),
    }),
});

export const { useGetStylesQuery } = stylesApi;
