import React, { useEffect, useState } from 'react';
import { Facebook as DefaultLoader } from 'react-content-loader';
import { useDispatch } from 'react-redux';

import { PageLayout, Section, AboutUs, AboutBusinessForm } from 'components';
import {
    aboutBusinessSchema,
    getNextStep,
    getPreviousStep,
    withLeadFetching,
} from 'utils';
import {
    DEMO_STEPS,
    EDemoSteps,
    IAboutBusiness,
    IUpdateAboutBusinessInfoPayload,
} from 'types';
import { setCurrentStep } from 'store';
import {
    useGetAboutLeadQuery,
    useUpdateAboutBusinessInfoMutation,
} from 'services';

import { IPropTypes } from './AboutBusinessPage.types';
import styles from './AboutBusinessPage.module.scss';

const currentStep = DEMO_STEPS[EDemoSteps.aboutBusiness];

function AboutBusinessPage(props: IPropTypes): JSX.Element {
    const { lead, router, isLoadingLead, isErrorLead } = props;
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const dispatch = useDispatch();

    const [
        updateAboutBusinessInfo,
        {
            isSuccess: isSuccessUpdateLead,
            isError: isErrorUpdateLead,
            isLoading: isLoadingUpdateLead,
            isUninitialized: isUninitializedUpdateLead,
        },
    ] = useUpdateAboutBusinessInfoMutation();

    const {
        data: aboutLeadInfo,
        isLoading: isLoadingAboutLeadInfo,
        isError: isErrorAboutLeadInfo,
    } = useGetAboutLeadQuery(router.query.id, {
        skip: !isUninitializedUpdateLead,
    });

    const isLoadingAboutUsContent =
        !isFormValid || isLoadingAboutLeadInfo || isLoadingLead;

    useEffect(() => {
        if (!isLoadingLead && !isErrorLead) {
            dispatch(setCurrentStep(EDemoSteps.aboutBusiness));
        }
    }, [isLoadingLead]);

    useEffect(() => {
        if (isSuccessUpdateLead && !isLoadingLead && !isErrorLead) {
            const leadId = lead.id;

            const redirectUrl = `/lead/${leadId}${
                DEMO_STEPS[getNextStep(EDemoSteps.aboutBusiness)].url
            }`;

            router.push(redirectUrl);
        }
    }, [isSuccessUpdateLead, isLoadingLead]);

    const handleLeadUpdate = (formData: IAboutBusiness) => {
        const payload: IUpdateAboutBusinessInfoPayload = {
            currentStep: EDemoSteps.aboutBusiness,
            lastStep: EDemoSteps.aboutBusiness, // BE needs this field
            leadId: lead?.id,
            businessDescription: formData.businessDescription,
            businessStart: formData.businessStart,
        };

        updateAboutBusinessInfo(payload);
    };

    const handleGoBack = (): void => {
        if (lead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getPreviousStep(EDemoSteps.aboutBusiness)].url
            }`;
            router.push(redirectUrl);
        }
    };

    const renderAboutUsContent = (): JSX.Element => {
        return (
            <AboutUs
                title={`About ${lead.businessName}`}
                description={aboutLeadInfo?.text || ''}
            />
        );
    };

    const getFormValidationState = (validationState: boolean) => {
        setIsFormValid(validationState);
    };

    const renderContent = (): JSX.Element => {
        return (
            <div className={styles.AboutBusinessPageSection}>
                <AboutBusinessForm
                    initialValues={{
                        businessDescription: lead?.businessDescription || '',
                        businessStart: lead?.businessStart || '',
                    }}
                    validationSchema={aboutBusinessSchema}
                    onSubmit={handleLeadUpdate}
                    onBack={handleGoBack}
                    isLoading={isLoadingUpdateLead || isLoadingAboutLeadInfo}
                    getFormValidationState={getFormValidationState}
                />
            </div>
        );
    };

    return (
        <div
            data-testid="about-business-page"
            className={styles.AboutBusinessPage}
        >
            <PageLayout
                hasError={
                    isErrorLead || isErrorAboutLeadInfo || isErrorUpdateLead
                }
                illustration={
                    <div className={styles.AboutBusinessPage_illustration}>
                        {isLoadingAboutUsContent ? (
                            <DefaultLoader />
                        ) : (
                            renderAboutUsContent()
                        )}
                    </div>
                }
            >
                <Section
                    title="Tell Us About Your Business"
                    className={styles.AboutBusinessPage_section}
                >
                    {renderContent()}
                </Section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, AboutBusinessPage);
