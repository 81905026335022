import { IPalette } from 'types';

export const styles: IPalette[] = [
    {
        fontStyle: 'pairing_1',
        colorPalette: 'bold',
        name: 'Bold',
        fontFamily: 'Lato',
        textColor: '#FBFCFE',
        backgroundColor: '#131313',
        colors: ['#F2ED44', '#000000', '#FBFCFE'],
    },
    {
        fontStyle: 'pairing_2',
        colorPalette: 'stylish',
        name: 'Stylish',
        fontFamily: 'Oxanium',
        textColor: '#FBFCFE',
        backgroundColor: '#071233',
        colors: ['#F1332D', '#BBD7F7', '#FBFCFE '],
    },
];
