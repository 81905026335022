import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getConfigVar } from 'utils';
import { ICategory } from 'types';

export const categoryApi = createApi({
    reducerPath: 'category',
    tagTypes: ['Category'],
    baseQuery: fetchBaseQuery({
        baseUrl: getConfigVar('REACT_APP_API_URL') as string,
    }),
    endpoints: (build) => ({
        getCategories: build.query<ICategory[], string>({
            query: () => `/category`,
            providesTags: ['Category'],
        }),
    }),
});

export const { useGetCategoriesQuery } = categoryApi;
