import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Icon, colors } from 'spoton-lib';

import { FormikInput } from 'components/FormikInput';

import { IPropTypes } from './AddOption.types';
import styles from './AddOption.module.scss';

function AddOption(props: IPropTypes): JSX.Element {
    const {
        id,
        label,
        placeholder,
        buttonText,
        helperText,
        isLoading = false,
        validationSchema,
        shouldResetOnSubmit = true,
        onAddOption,
    } = props;

    return (
        <Formik
            enableReinitialize
            initialValues={{ addOption: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm, validateForm }) => {
                onAddOption(values.addOption);

                if (shouldResetOnSubmit) {
                    resetForm();
                    validateForm();
                }
            }}
            validateOnMount
        >
            {({ isValid }) => (
                <Form className={styles.AddOptionForm}>
                    <FormikInput
                        id={id || 'addOption'}
                        name={id || 'addOption'}
                        label={label}
                        placeholder={placeholder}
                        helperText={helperText}
                        className={styles.AddOptionForm_input}
                    />
                    <Button
                        data-testid="addOptionButton"
                        type="submit"
                        variant="secondary"
                        disabled={!isValid || isLoading}
                        isLoading={isLoading}
                        className={styles.Button}
                    >
                        <Icon
                            size={24}
                            name="AddIcon"
                            color={colors.primary50}
                            className={styles.Button_icon}
                        />
                        {buttonText}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

export default AddOption;
