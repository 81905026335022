import {
    IFeaturedImage,
    EFeaturedImageSource,
    IGoogleApiResponse,
    IUnsplashApiResponse,
} from 'types';

export const imageMock: IFeaturedImage = {
    id: 1,
    url: 'https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=google-api-1&key=google_api_key',
    source: EFeaturedImageSource.google,
};

export const googleApiResponse: IGoogleApiResponse = {
    result: {
        photos: [
            {
                height: 300,
                width: 300,
                photoReference: 'google-api-1',
            },
            {
                height: 300,
                width: 300,
                photoReference: 'google-api-2',
            },
            {
                height: 300,
                width: 300,
                photoReference: 'google-api-3',
            },
            {
                height: 300,
                width: 300,
                photoReference: 'google-api-4',
            },
            {
                height: 300,
                width: 300,
                photoReference: 'google-api-5',
            },
        ],
    },
};

export const unsplashApiResponse: IUnsplashApiResponse = {
    data: {
        results: [
            {
                id: 1,
                height: 400,
                width: 400,
                description: 'Unsplash mock',
                urls: {
                    raw: 'unsplash-stock-1.png',
                    full: 'unsplash-stock-1.png',
                    regular: 'unsplash-stock-1.png',
                    small: 'unsplash-stock-1.png',
                    thumb: 'unsplash-stock-1.png',
                },
                links: {
                    self: 'unsplash-stock-1.png',
                    html: 'unsplash-stock-1.png',
                    download: 'unsplash-stock-1.png',
                },
            },
            {
                id: 2,
                height: 400,
                width: 400,
                description: 'Unsplash mock',
                urls: {
                    raw: 'unsplash-stock-2.png',
                    full: 'unsplash-stock-2.png',
                    regular: 'unsplash-stock-2.png',
                    small: 'unsplash-stock-2.png',
                    thumb: 'unsplash-stock-2.png',
                },
                links: {
                    self: 'unsplash-stock-2.png',
                    html: 'unsplash-stock-2.png',
                    download: 'unsplash-stock-2.png',
                },
            },
            {
                id: 3,
                height: 400,
                width: 400,
                description: 'Unsplash mock',
                urls: {
                    raw: 'unsplash-stock-3.png',
                    full: 'unsplash-stock-3.png',
                    regular: 'unsplash-stock-3.png',
                    small: 'unsplash-stock-3.png',
                    thumb: 'unsplash-stock-3.png',
                },
                links: {
                    self: 'unsplash-stock-3.png',
                    html: 'unsplash-stock-3.png',
                    download: 'unsplash-stock-3.png',
                },
            },
            {
                id: 4,
                height: 400,
                width: 400,
                description: 'Unsplash mock',
                urls: {
                    raw: 'unsplash-stock-4.png',
                    full: 'unsplash-stock-4.png',
                    regular: 'unsplash-stock-4.png',
                    small: 'unsplash-stock-4.png',
                    thumb: 'unsplash-stock-4.png',
                },
                links: {
                    self: 'unsplash-stock-4.png',
                    html: 'unsplash-stock-4.png',
                    download: 'unsplash-stock-4.png',
                },
            },
            {
                id: 5,
                height: 400,
                width: 400,
                description: 'Unsplash mock',
                urls: {
                    raw: 'unsplash-stock-5.png',
                    full: 'unsplash-stock-5.png',
                    regular: 'unsplash-stock-5.png',
                    small: 'unsplash-stock-5.png',
                    thumb: 'unsplash-stock-5.png',
                },
                links: {
                    self: 'unsplash-stock-5.png',
                    html: 'unsplash-stock-5.png',
                    download: 'unsplash-stock-5.png',
                },
            },
        ],
    },
};
