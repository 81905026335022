import React from 'react';

import { IScreenPropTypes } from './SplitScreen.types';
import styles from './SplitScreen.module.scss';

export function RightScreen(props: IScreenPropTypes): JSX.Element {
    return (
        <div
            className={`${styles.SplitScreen_section} ${
                styles.SplitScreen_section___right
            } ${props.className ? props.className : ''}`}
        >
            {props.children}
        </div>
    );
}

RightScreen.displayName = 'SplitScreen.Right';

export default RightScreen;
