import React, { useEffect, useState, useRef } from 'react';
import { Banner } from 'spoton-lib';

import {
    getNextStep,
    withLeadFetching,
    getPreviousStep,
    contactSchema,
} from 'utils';
import {
    EDemoSteps,
    DEMO_STEPS,
    IUpdateContactInfoPayload,
    IContactInfo,
    IUpdateGeneratedLeadPagesPayload,
} from 'types';
import {
    useUpdateContactInfoMutation,
    useUpdateGeneratedLeadPagesMutation,
} from 'services';
import { Section, PageLayout, ContactInformationForm } from 'components';
import { Ellipses, Dots, Diego } from 'assets';

import { IPropTypes } from './LeadContactPage.types';
import styles from './LeadContactPage.module.scss';
import BuildingWebsite from './BuildingWebsite.component';

const currentStep = DEMO_STEPS[EDemoSteps.contactInfo];
const TIMEOUT = 5 * 1000;

export function LeadContactPage(props: IPropTypes): JSX.Element {
    const { lead, router, isLoadingLead, isErrorLead } = props;

    const [newContact, setNewContact] = useState<IContactInfo>({
        name: '',
        email: '',
        phoneNumber: '',
    });

    /* TODO: Set `isBuildingWebsite` to false when Contact Information is required */
    const [isBuildingWebsite, setIsBuildingWebsite] = useState<boolean>(true);
    const buildingWebsiteTimer = useRef<NodeJS.Timeout>();

    /* TODO: Uncomment when Contact Information is required
        useEffect(() => {
            if (!isLoadingLead && !isErrorLead) {
                setNewContact({
                    name: lead.contact?.name,
                    email: lead.contact?.email,
                    phoneNumber: lead.contact?.phoneNumber,
                });

                dispatch(setCurrentStep(EDemoSteps.contactInfo));
            }
        }, [isLoadingLead]);
    */

    const [
        updateContactInfo,
        {
            isSuccess: isSuccessContact,
            isLoading: isLoadingContact,
            isError: isErrorContact,
        },
    ] = useUpdateContactInfoMutation();

    const [
        updateGeneratedLeadPages,
        { isSuccess: isSuccessPages, isError: isErrorPages },
    ] = useUpdateGeneratedLeadPagesMutation();

    const hasError = isErrorContact || isErrorLead || isErrorPages;

    const handleContinue = async (contact: IContactInfo) => {
        const contactPayload: IUpdateContactInfoPayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.contactInfo,
            ...contact,
        };
        updateContactInfo(contactPayload).then(() => {
            setNewContact(contact);
        });

        const pagesPayload: IUpdateGeneratedLeadPagesPayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.contactInfo,
        };
        updateGeneratedLeadPages(pagesPayload);
    };

    // TODO: remove useEffect when Contact Information is required
    useEffect(() => {
        if (!isLoadingLead && !isErrorLead) {
            handleContinue(newContact);
        }
    }, [isLoadingLead]);

    const handleCancel = () => {
        if (lead) {
            const redirectUrl = `/lead/${lead.id}${
                DEMO_STEPS[getPreviousStep(EDemoSteps.contactInfo)].url
            }`;
            router.push(redirectUrl);
        }
    };

    useEffect(() => {
        if (isSuccessContact && isSuccessPages) {
            /* TODO: Uncomment when Contact Information is required
                setIsBuildingWebsite(true);
            */

            buildingWebsiteTimer.current = setTimeout(() => {
                setIsBuildingWebsite(false);

                const redirectUrl = `/lead/${lead.id}${
                    DEMO_STEPS[getNextStep(EDemoSteps.contactInfo)].url
                }`;
                router.push(redirectUrl);
            }, TIMEOUT);

            return () => {
                clearTimeout(buildingWebsiteTimer.current);
            };
        }
    }, [isSuccessContact, isSuccessPages]);

    const renderIllustration = () => {
        return (
            <div className={styles.Illustration}>
                <div className={styles.Photo}>
                    <Ellipses className={styles.Ellipses} />
                    <Diego className={styles.Photo_image} />
                    <Dots className={styles.Dots} />
                </div>
                <div className={styles.Quotes}>
                    <div className={styles.Quotes_text}>
                        “We need passion every day to undertake something new
                        and face new challenges”
                    </div>

                    <div className={styles.Quotes_details}>
                        <div className={styles.Quotes_name}>Diego</div>
                        <div className={styles.Quotes_role}>
                            Designer Mexico City
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderErrorBanner = () => {
        return (
            <div className={styles.BuildingAnimation}>
                <Banner
                    className={styles.BuildingAnimation_error}
                    message="Something went wrong. Please try again."
                    variant="danger"
                />
            </div>
        );
    };

    return (
        <div className={styles.LeadContactPage}>
            {isBuildingWebsite ? (
                <>
                    {hasError && renderErrorBanner()}
                    <BuildingWebsite />
                </>
            ) : (
                <PageLayout
                    hasError={hasError}
                    illustration={renderIllustration()}
                >
                    <Section
                        className={styles.LeadContactPage_section}
                        title="One more thing!"
                        subtitle="Before we continue, please provide your contact info so we can talk more about your business"
                    >
                        <ContactInformationForm
                            initialValues={{
                                name: newContact.name,
                                email: newContact.email,
                                phoneNumber: newContact.phoneNumber,
                            }}
                            validationSchema={contactSchema}
                            isLoading={isLoadingContact}
                            onBack={handleCancel}
                            onSubmit={handleContinue}
                        />
                    </Section>
                </PageLayout>
            )}
        </div>
    );
}

export default withLeadFetching(currentStep, LeadContactPage);
