import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Facebook as DefaultLoader } from 'react-content-loader';
import { Button } from 'spoton-lib';

import {
    withLeadFetching,
    getNextStep,
    nameAddressPhoneSchema,
    namePhoneSchema,
    useScript,
    useLocalStorage,
    useRouter,
    getConfigVar,
} from 'utils';
import {
    EDemoSteps,
    DEMO_STEPS,
    IUpdateBusinessInfoPayload,
    IBusinessInfo,
    IFeaturedImagePayload,
    EFeaturedImageSource,
    IFeaturedImage,
    IBusinessDetail,
} from 'types';
import {
    useCreateLeadMutation,
    useUpdateLeadBusinessInfoMutation,
} from 'services';

import {
    Section,
    BusinessDetailForm,
    StepperIllustration,
    GoogleAutocomplete,
    BusinessLocation,
    PageLayout,
} from 'components';
import { StoreStreet } from 'assets';

import { IPropTypes } from './BusinessInfoPage.types';
import styles from './BusinessInfoPage.module.scss';
import { setCurrentStep } from 'store';

const currentStep = DEMO_STEPS[EDemoSteps.businessInfo];

enum EBusinessInfoSteps {
    businessLookup = 'businessLookup',
    businessInfo = 'businessInfo',
}

export function BusinessInfoPage(props: IPropTypes): JSX.Element {
    const dispatch = useDispatch();
    const {
        lead,
        router = useRouter(),
        isLoadingLead = true,
        isErrorLead,
    } = props;
    const { query } = router;

    const [businessInfo, setBusinessInfo] = useState<IBusinessInfo>({
        businessName: '',
        phoneNumber: '',
        location: '',
        geometry: undefined,
    });

    const [isLoading, setIsLoading] = useState(isLoadingLead);
    const [featuredImages, setFeaturedImages] = useState<
        IFeaturedImagePayload[]
    >([]);
    useScript(
        `https://maps.googleapis.com/maps/api/js?key=${getConfigVar(
            'REACT_APP_PLACES_API_KEY',
        )}&libraries=places`,
    );
    const [, setleadIdLocal] = useLocalStorage<string>('leadId');
    const [placeIdLocal, setPlaceIdLocal] = useLocalStorage<string>('placeId');
    const [, setImagesLocal] =
        useLocalStorage<IFeaturedImage[]>('featuredImages');

    const [step, setStep] = useState<EBusinessInfoSteps>(
        EBusinessInfoSteps.businessLookup,
    );

    /**
     * - Prepares the local state based on the lead
     * - Chooses the right step for the lead,
     *      either "business lookup" for new leads
     *      or "business details form" for existing leads
     */
    useEffect(() => {
        if (lead) {
            const {
                businessName,
                phoneNumber,
                location,
                googlePlacesId,
                geometry,
            } = lead;
            setBusinessInfo({
                businessName: businessName || businessInfo.businessName,
                phoneNumber: phoneNumber || businessInfo.phoneNumber,
                location: location || businessInfo.location,
                googlePlacesId,
                geometry,
            });
            if (location) {
                setStep(EBusinessInfoSteps.businessInfo);
            }

            // disable loading state after lead info is stored at local state
            setIsLoading(false);
            dispatch(setCurrentStep(EDemoSteps.businessInfo));
        }
    }, [lead]);

    const [updateBusinessInfo, updateBusinessInfoRequestState] =
        useUpdateLeadBusinessInfoMutation();

    const [createLead, createLeadRequestState] = useCreateLeadMutation();

    /**
     * Updates 'placeId' and 'featuredImages' on localStorage if the placeId changed
     */
    const updateLocalStorage = async () => {
        if (placeIdLocal !== businessInfo.googlePlacesId) {
            await setPlaceIdLocal(String(businessInfo.googlePlacesId));
            await setImagesLocal(featuredImages);
        }
    };

    /**
     * - Persists local state,
     *     either by creating a new lead
     *     or updating an existing lead
     * - Updates localStorage
     */
    const handleLeadUpdate = (formData: IBusinessDetail) => {
        const {
            businessName,
            location,
            phoneNumber,
            googlePlacesId,
            geometry,
        } = businessInfo;

        const payload: IUpdateBusinessInfoPayload = {
            leadId: lead?.id,
            currentStep: EDemoSteps.businessInfo,
            businessName: formData.businessName || businessName,
            location: formData.address || location,
            googlePlacesId,
            phoneNumber: formData.phoneNumber || phoneNumber,
            geometry,
            ...(!lead?.id && { merchantId: query?.merchant_id || null }),
        };

        updateLocalStorage();

        lead.id ? updateBusinessInfo(payload) : createLead(payload);
    };

    /**
     * - Stores leadId at localStorage when creating a new lead
     * - Redirects to next step when api request is successful
     */
    useEffect(() => {
        if (
            updateBusinessInfoRequestState.isSuccess ||
            createLeadRequestState.isSuccess
        ) {
            let leadId = lead.id;
            const { isSuccess, data } = createLeadRequestState;
            if (isSuccess && data) {
                leadId = data.id;
                setleadIdLocal(data.id);
            }

            const redirectUrl = `/lead/${leadId}${
                DEMO_STEPS[getNextStep(EDemoSteps.businessInfo)].url
            }`;
            router.push(redirectUrl);
        }
    }, [updateBusinessInfoRequestState, createLeadRequestState]);

    const handlePlaceResult = async (place: google.maps.places.PlaceResult) => {
        if (place) {
            setBusinessInfo({
                businessName:
                    step === EBusinessInfoSteps.businessLookup && place.name
                        ? place.name
                        : businessInfo.businessName,
                phoneNumber:
                    step === EBusinessInfoSteps.businessLookup &&
                    place.formatted_phone_number
                        ? place.formatted_phone_number
                        : businessInfo.phoneNumber,
                location: place.formatted_address
                    ? place.formatted_address
                    : businessInfo.location,
                geometry: place.geometry?.location
                    ? place.geometry.location.toJSON()
                    : businessInfo.geometry,
                googlePlacesId: place.place_id,
            });
            if (
                /**
                 * if the google places lookup returned photos
                 * and the placeId changed
                 * => format the photos and store at featuredImages
                 */
                place.photos &&
                businessInfo.googlePlacesId !== place.place_id &&
                lead
            ) {
                setFeaturedImages(
                    place.photos.map((photo) => {
                        return {
                            source: EFeaturedImageSource.google,
                            url: photo.getUrl(),
                        };
                    }),
                );
            }
        }
        setStep(EBusinessInfoSteps.businessInfo);
    };

    const handleGoBack = (): void => {
        setStep(EBusinessInfoSteps.businessLookup);
    };

    const handleBusinessNameValue = (query: string) => {
        setBusinessInfo((prevInfo) => ({
            ...prevInfo,
            businessName: query,
        }));
    };

    const renderBusinessLookup = (): JSX.Element => {
        return (
            <form
                className={styles.BusinessLookupForm}
                onSubmit={(e) => {
                    e.preventDefault();
                    setStep(EBusinessInfoSteps.businessInfo);
                }}
            >
                <div className={styles.BusinessLookupForm_form}>
                    <GoogleAutocomplete
                        id="businessName"
                        name="businessName"
                        label="Business Name"
                        type="establishment"
                        onChange={handlePlaceResult}
                        initialValue={businessInfo.businessName}
                        getQuery={handleBusinessNameValue}
                    />
                </div>
                <div className={styles.BusinessLookupForm_actions}>
                    <Button
                        className={styles.Button}
                        type="submit"
                        variant="primary"
                    >
                        Continue
                    </Button>
                </div>
            </form>
        );
    };

    const renderBusinessInfo = (): JSX.Element => {
        return (
            <BusinessDetailForm
                initialValues={{
                    businessName: businessInfo.businessName,
                    phoneNumber: businessInfo.phoneNumber,
                    address: businessInfo.location,
                }}
                isLoading={updateBusinessInfoRequestState.isLoading}
                onBack={handleGoBack}
                onSubmit={handleLeadUpdate}
                onPlaceChange={handlePlaceResult}
                validationSchema={
                    businessInfo.googlePlacesId
                        ? nameAddressPhoneSchema
                        : namePhoneSchema
                }
            />
        );
    };

    const renderLocationDisplay = (): JSX.Element => {
        return businessInfo.geometry ? (
            <div className={styles.Map}>
                <BusinessLocation position={businessInfo.geometry} />
            </div>
        ) : (
            <StoreStreet className={styles.Illustration} />
        );
    };

    const renderSectionContent = (): JSX.Element => {
        return step === EBusinessInfoSteps.businessLookup
            ? renderBusinessLookup()
            : renderBusinessInfo();
    };

    return (
        <div
            data-testid="business-info-page"
            className={styles.BusinessInfoPage}
        >
            <PageLayout
                hasError={
                    createLeadRequestState.isError ||
                    updateBusinessInfoRequestState.isError ||
                    isErrorLead
                }
                illustration={
                    businessInfo.geometry ? (
                        renderLocationDisplay()
                    ) : (
                        <StepperIllustration
                            hasIcon
                            title="We’re all about your success"
                            text="Having a great website is an excellent way to build trust and get found on Google."
                        >
                            {renderLocationDisplay()}
                        </StepperIllustration>
                    )
                }
            >
                <Section
                    className={styles.BusinessInfoPage_section}
                    title={
                        businessInfo.geometry
                            ? 'We found your location'
                            : 'Enter your business name'
                    }
                    subtitle={
                        businessInfo.geometry
                            ? 'We’ll use this info from Google in your new website'
                            : 'We’ll import business info right into your new website'
                    }
                >
                    {isLoading ? <DefaultLoader /> : renderSectionContent()}
                </Section>
            </PageLayout>
        </div>
    );
}

export default withLeadFetching(currentStep, BusinessInfoPage);
