import React from 'react';
import { Banner } from 'spoton-lib';

import { useMedia } from 'utils';

import { SplitScreen } from 'components';

import { IPropTypes } from './PageLayout.types';
import styles from './PageLayout.module.scss';

export function PageLayout(props: IPropTypes): JSX.Element {
    const {
        children,
        illustration,
        hasError,
        hasWhiteBackground,
        errorMessage,
    } = props;
    const isDesktop = useMedia<boolean>(['(min-width: 1025px)'], [true], false);
    const errorMsg = errorMessage || 'Something went wrong. Please try again.';

    const renderDesktopLayout = () => (
        <SplitScreen>
            <SplitScreen.Left>{children}</SplitScreen.Left>
            <SplitScreen.Right
                className={`${
                    hasWhiteBackground &&
                    `${styles.SplitScreen} ${styles.SplitScreen___white}`
                }`}
            >
                {illustration}
            </SplitScreen.Right>
        </SplitScreen>
    );

    const renderMobileLayout = () => (
        <div className={`${styles.Layout} ${styles.Layout___mobile}`}>
            {illustration}
            {children}
        </div>
    );

    return (
        <>
            {hasError && (
                <Banner
                    className={styles.Layout_error}
                    message={errorMsg}
                    variant="danger"
                />
            )}
            {isDesktop ? renderDesktopLayout() : renderMobileLayout()}
        </>
    );
}

export default PageLayout;
