import { IContactInfo } from 'types';

export const contactInformationMock: IContactInfo = {
    name: 'John Doe',
    email: 'john@mail.com',
    phoneNumber: '5555555555',
};

export const contactInformationMockBlank: IContactInfo = {
    name: '',
    email: '',
    phoneNumber: '',
};

export const contactInformationInvalidEmail: IContactInfo = {
    name: 'John Doe',
    email: 'john@mail',
    phoneNumber: '5555555555',
};

export const contactInformationInvalidPhoneNumber: IContactInfo = {
    name: 'John Doe',
    email: 'john@mail.com',
    phoneNumber: '55555',
};
