import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './ImagePickerPage.module.scss';

export function LoadingState(): JSX.Element {
    return (
        <div className={styles.LoadingState} data-testid="images-loaders">
            <ContentLoader speed={1} viewBox="0 0 462 462">
                <rect x="0" y="0" rx="4" ry="4" width="138" height="138" />
                <rect x="162" y="0" rx="4" ry="4" width="138" height="138" />
                <rect x="324" y="0" rx="4" ry="4" width="138" height="138" />
                <rect x="0" y="162" rx="4" ry="4" width="138" height="138" />
                <rect x="162" y="162" rx="4" ry="4" width="138" height="138" />
                <rect x="324" y="162" rx="4" ry="4" width="138" height="138" />
                <rect x="0" y="324" rx="4" ry="4" width="138" height="138" />
                <rect x="162" y="324" rx="4" ry="4" width="138" height="138" />
                <rect x="324" y="324" rx="4" ry="4" width="138" height="138" />
            </ContentLoader>
        </div>
    );
}

export default LoadingState;
