import React from 'react';
import { Icon, colors } from 'spoton-lib';

import { CheckSolidIcon } from 'assets';

import { IPropTypes } from './SpotonService.types';
import styles from './SpotonService.module.scss';

export function SpotonService(props: IPropTypes): JSX.Element {
    const { service, onServiceSelect, isSelected = false } = props;
    const { id, title, icon } = service;

    const computeSelectedClasses = (): string =>
        `${isSelected && styles.SpotonService___active}`;

    return (
        <button
            role="checkbox"
            aria-checked={isSelected}
            className={`${styles.SpotonService} ${computeSelectedClasses()}`}
            onClick={() => {
                onServiceSelect(id);
            }}
        >
            {isSelected && (
                <CheckSolidIcon className={styles.SpotonService_checkIcon} />
            )}

            <Icon
                size={44}
                name={icon}
                alt={title}
                className={styles.SpotonService_icon}
                color={colors.primary30}
            />

            <span className={styles.SpotonService_title}>{title}</span>
        </button>
    );
}

export default SpotonService;
