import { ICategory } from 'types';
import { ISubCategory } from 'types';

export const subAutoBodyshop: ISubCategory = {
    id: 2,
    name: 'Auto Bodyshop',
    slug: 'auto-bodyshop',
    parentCategory: 1,
};

export const subAutoCarDealer: ISubCategory = {
    id: 3,
    name: 'Auto Car Dealer',
    slug: 'auto-car-dealer',
    parentCategory: 1,
};

export const subAutoCarWash: ISubCategory = {
    id: 4,
    name: 'Auto Car Wash',
    slug: 'auto-car-wash',
    parentCategory: 1,
};

export const subAutoCustomization: ISubCategory = {
    id: 5,
    name: 'Auto Customization',
    slug: 'auto-customization',
    parentCategory: 1,
};

export const categoryAuto: ICategory = {
    id: 1,
    icon: 'CarIcon',
    name: 'Auto',
    slug: 'auto',
    subCategories: [
        subAutoBodyshop,
        subAutoCarDealer,
        subAutoCarWash,
        subAutoCustomization,
    ],
};

export const categoryArt: ICategory = {
    id: 6,
    icon: 'ThemesIcon',
    name: 'Art',
    slug: 'art',
    subCategories: [],
};

const subGeneral: ISubCategory = {
    id: 8,
    name: 'General',
    parentCategory: 4,
    slug: 'general',
};

const subAsian: ISubCategory = {
    id: 9,
    name: 'Asian',
    parentCategory: 4,
    slug: 'asian',
};

const subBreakfast: ISubCategory = {
    id: 10,
    name: 'Breakfast/Brunch',
    parentCategory: 4,
    slug: 'breakfast-brunch',
};

const subBuffet: ISubCategory = {
    id: 11,
    name: 'Buffet',
    parentCategory: 4,
    slug: 'buffet',
};

const subCaribbean: ISubCategory = {
    id: 12,
    name: 'Caribbean',
    parentCategory: 4,
    slug: 'caribbean',
};

const subChinese: ISubCategory = {
    id: 13,
    name: 'Chinese',
    parentCategory: 4,
    slug: 'chinese',
};

const subCuban: ISubCategory = {
    id: 14,
    name: 'Cuban',
    parentCategory: 4,
    slug: 'cuban',
};

const subDiners: ISubCategory = {
    id: 15,
    name: 'Diners',
    parentCategory: 4,
    slug: 'diners',
};

const subIndian: ISubCategory = {
    id: 16,
    name: 'Indian',
    parentCategory: 4,
    slug: 'indian',
};

const subItalian: ISubCategory = {
    id: 17,
    name: 'Italian',
    parentCategory: 4,
    slug: 'italian',
};

const subKorean: ISubCategory = {
    id: 18,
    name: 'Korean',
    parentCategory: 4,
    slug: 'korean',
};

export const categoryReligious: ICategory = {
    id: 19,
    icon: 'ThemesIcon',
    name: 'Religious Organizations',
    slug: 'religious-organizations',
    subCategories: [],
};

export const categoryRestaurant: ICategory = {
    id: 7,
    icon: 'RestaurantsIcon',
    name: 'Restaurant',
    slug: 'restaurant',
    subCategories: [
        subGeneral,
        subAsian,
        subBreakfast,
        subBuffet,
        subCaribbean,
        subChinese,
        subCuban,
        subDiners,
        subIndian,
        subItalian,
        subKorean,
    ],
};

export const categories: ICategory[] = [
    categoryAuto,
    categoryArt,
    categoryReligious,
    categoryRestaurant,
];
