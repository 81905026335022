import React from 'react';
import TagManager from 'react-gtm-module';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';

import { stepperSelectors } from 'store';
import { DEMO_STEPS, EDemoSteps, IDemoStep } from 'types';
import { Navbar, Stepper, PreviewActions } from 'components';
import { leadEmptyState } from 'mocks';

import {
    BusinessCategoryPage,
    BusinessInfoPage,
    SpotonServicesPage,
    SiteStylingPage,
    LeadContactPage,
    AboutBusinessPage,
    BusinessServicesPage,
    ImagePickerPage,
    PickPagesPage,
    PreviewPage,
    DemoToolLandingPage,
    DomainSelectionPage,
} from 'pages';
import { ScrollToTop } from 'utils';

import styles from './App.module.scss';
import './fadeTransition.scss';
import { getConfigVar } from '../../utils/config.utils';

interface IRouteParams {
    id: string;
}

// TODO: create 404 page
function NotFound() {
    // TODO: get landing page url
    return <Redirect to="/" />;
}

const tagManagerArgs = {
    gtmId: getConfigVar('REACT_APP_GOOGLE_TAG_MANAGER_KEY_SPOTON') as string,
};

const previewStep = DEMO_STEPS[EDemoSteps.preview];

TagManager.initialize(tagManagerArgs);

// TODO: Move this to it's own page component
function DemoToolBasePage() {
    const { path } = useRouteMatch();
    const { id } = useParams<IRouteParams>();
    const location = useLocation();

    return (
        <TransitionGroup style={{ height: '100%' }}>
            <CSSTransition
                key={location.key}
                timeout={500}
                classNames="fade"
                unmountOnExit
            >
                <Switch location={location}>
                    <Route
                        path={`${path}/business-information`}
                        component={BusinessInfoPage}
                    />
                    <Route
                        path={`${path}/business-category`}
                        component={BusinessCategoryPage}
                    />
                    <Route
                        path={`${path}/spoton-services`}
                        component={SpotonServicesPage}
                    />
                    <Route
                        path={`${path}/about-business`}
                        component={AboutBusinessPage}
                    />
                    <Route
                        path={`${path}/business-services`}
                        component={BusinessServicesPage}
                    />
                    <Route
                        path={`${path}/featured-images`}
                        component={ImagePickerPage}
                    />
                    <Route
                        path={`${path}/pick-pages`}
                        component={PickPagesPage}
                    />
                    <Route
                        path={`${path}/site-styling`}
                        component={SiteStylingPage}
                    />
                    <Route
                        path={`${path}/domain`}
                        component={DomainSelectionPage}
                    />
                    <Route
                        path={`${path}/contact`}
                        component={LeadContactPage}
                    />
                    <Route path={`${path}/preview`} component={PreviewPage} />
                    <Route path={`${path}`}>
                        <Redirect to={`/lead/${id}/business-information`} />
                    </Route>
                    <Route path={`${path}/*`}>
                        <Redirect to={`/lead/${id}/business-information`} />
                    </Route>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
}

function StepperComponent(props: IDemoStep) {
    const { index } = props;

    return index !== previewStep.index ? <Stepper currentStep={props} /> : null;
}

function PreviewActionsComponent(props: IDemoStep) {
    const { index } = props;

    const match = useRouteMatch<{ id: string }>('/lead/:id');
    const { id } = match?.params || {};

    return index === previewStep.index ? <PreviewActions leadId={id} /> : null;
}

export function App(): JSX.Element {
    const step = useSelector(stepperSelectors.selectCurrentStep);
    const currentStep = DEMO_STEPS[step];

    return (
        <Router>
            <ScrollToTop />
            <main className={styles.App}>
                <div className={styles.App_navbar}>
                    <Navbar
                        center={
                            <StepperComponent
                                url={currentStep.url}
                                index={currentStep.index}
                            />
                        }
                        right={
                            <PreviewActionsComponent
                                url={currentStep.url}
                                index={currentStep.index}
                            />
                        }
                    />
                </div>
                <div className={styles.App_content}>
                    <Switch>
                        <Route path="/" exact>
                            <DemoToolLandingPage lead={leadEmptyState} />
                        </Route>
                        <Route path="/lead/:id" component={DemoToolBasePage} />
                        <Route path="/*" component={NotFound} />
                    </Switch>
                </div>
            </main>
        </Router>
    );
}

export default App;
