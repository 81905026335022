import React, { useState, useRef, useEffect } from 'react';
import { IconButton } from 'spoton-lib';
import { FileUpload } from 'assets';
import { IPropTypes } from './FileLoader.types';
import styles from './FileLoader.module.scss';

// 1 MB limit
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1000000;

export function FileLoader(props: IPropTypes): JSX.Element {
    const {
        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
        backgroundColor,
        imageCDN,
        setFileError,
        uploadFile,
        ...inputProps
    } = props;
    const [file, setFile] = useState<File | null>(null);
    const [imgUrl, setImgUrl] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const loadImgAsUrl = (file: File) => {
        const reader = new FileReader();
        reader.onload = (ev) => setImgUrl(ev.target?.result as string);
        reader.readAsDataURL(file);
    };

    const handleFile = (file: File | null) => {
        if (file && file.size <= maxFileSizeInBytes) {
            setFile(file);
            loadImgAsUrl(file);
            uploadFile(file);
        }

        if (file && file?.size > maxFileSizeInBytes) {
            setFileError(true);
        }
    };

    const handleRemove = () => {
        setFile(null);
        setImgUrl(null);
        uploadFile(null);
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const droppedFile = Array.from(event.dataTransfer.files)[0];

        handleFile(droppedFile);
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLFormElement>) => {
        const pastedItems = Array.from(event.clipboardData.items);
        const pastedFile = pastedItems
            .filter((item) => item.kind === 'file')
            .map((item) => item.getAsFile())[0];

        handleFile(pastedFile);
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const uploadedFile = Array.from(event.target.files)[0];

            handleFile(uploadedFile);
        }
    };

    const handleClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (inputRef.current) {
            inputRef.current?.click();
        }
    };

    useEffect(() => {
        if (imageCDN) {
            setImgUrl(imageCDN);
        }
    }, [imageCDN]);

    return (
        <form
            id="form-file-upload"
            data-testid="file-upload-form"
            className={`${styles.FileLoader} ${
                !imgUrl ? styles.FileLoader_filled : ''
            }`}
            onDrop={handleDrop}
            onPaste={handlePaste}
            onDragOver={(event) => event.preventDefault()}
        >
            {imgUrl ? (
                <>
                    <IconButton
                        name="DeleteIcon"
                        variant="danger"
                        onClick={handleRemove}
                        className={styles.FileLoader_previewBtn}
                        data-testid="file-delete"
                    />
                    <div
                        className={styles.FileLoader_preview}
                        style={{ backgroundColor }}
                    >
                        <img
                            className={styles.FileLoader_previewImg}
                            src={imgUrl}
                            alt={`preview ${file?.name}`}
                            data-testid="upload-preview"
                        />
                    </div>
                </>
            ) : (
                <>
                    <label
                        className={styles.FileLoader_label}
                        id="label-file-upload"
                        data-testid="file-upload"
                        htmlFor="input-file-upload"
                        onClick={handleClick}
                    >
                        <FileUpload />
                        <p className={styles.FileLoader_labelSubtitle}>
                            Select Your Logo Image For Upload
                        </p>
                        <button className={styles.FileLoader_button}>
                            <p className={styles.FileLoader_buttonText}>
                                or Drag and Drop, Copy and Paste files
                            </p>
                        </button>
                    </label>
                    <input
                        hidden
                        id="input-file-upload"
                        data-testid="input-file-upload"
                        type="file"
                        ref={inputRef}
                        className={styles.FileLoader_input}
                        onChange={handleFileUpload}
                        {...inputProps}
                    />
                </>
            )}
        </form>
    );
}

export default FileLoader;
