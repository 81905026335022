import React, { useEffect, useState } from 'react';

import { CheckSolidCompleted } from 'assets';
import { DEMO_STEPS, EDemoSteps } from 'types';

import { IPropTypes, IStepperItem, IStepGroup } from './Stepper.types';
import styles from './Stepper.module.scss';

function StepperItem(props: IStepperItem): JSX.Element {
    const { isCompleted, isActive, title, step } = props;

    const computeActiveStepClasses = (): string =>
        `${isActive && styles.Item_step___active}`;

    const computeActiveClasses = (): string =>
        `${isActive && styles.Title_text___active}`;

    return (
        <li className={styles.Item}>
            <div className={styles.Item_icon}>
                {isCompleted ? (
                    <CheckSolidCompleted className={styles.Icon} />
                ) : (
                    <div
                        className={`${
                            styles.Item_step
                        } ${computeActiveStepClasses()}`}
                    >
                        <span className={styles.Item_number}>{step}</span>
                    </div>
                )}
            </div>
            <div className={styles.Title}>
                <span
                    className={`${styles.Title_text} ${computeActiveClasses()}`}
                >
                    {title}
                </span>
            </div>
        </li>
    );
}

export function Stepper(props: IPropTypes): JSX.Element {
    const { currentStep } = props;
    const [active, setActive] = useState<IStepGroup | ''>('');

    const businessInfoStep = DEMO_STEPS[EDemoSteps.businessInfo];
    const siteStylingStep = DEMO_STEPS[EDemoSteps.siteStyling];
    const domainPreferenceStep = DEMO_STEPS[EDemoSteps.domainPreference];
    const contactInfoStep = DEMO_STEPS[EDemoSteps.contactInfo];

    const steps = [
        {
            title: 'Enter Business Info',
            name: 'your_business',
            completeOn: businessInfoStep.index,
        },
        {
            title: 'Create Content',
            name: 'pick_style',
            completeOn: siteStylingStep.index,
        },
        {
            title: 'Connect Domain',
            name: 'domain_selection',
            // TODO: Update when /domain/new and /domain/existing are implemented
            completeOn: domainPreferenceStep.index,
        },
        {
            title: 'Get AI Website',
            name: 'website_demo',
            completeOn: contactInfoStep.index,
        },
    ];

    useEffect(() => {
        const step = currentStep.index;

        switch (step) {
            case 0:
                setActive('your_business');
                break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                setActive('pick_style');
                break;
            case 8:
                setActive('domain_selection');
                break;
            case 9:
                setActive('website_demo');
                break;
            default:
                setActive('final_demo');
                break;
        }
    }, [currentStep]);

    return (
        <ul data-testid="stepper" className={styles.Stepper}>
            {steps.map((step, index) => {
                return (
                    <StepperItem
                        key={index}
                        isActive={active == step.name}
                        isCompleted={currentStep.index > step.completeOn}
                        title={step.title}
                        step={index + 1}
                    />
                );
            })}
        </ul>
    );
}

export default Stepper;
