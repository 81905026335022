import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'spoton-lib';

import { FormikInput } from 'components/FormikInput';

import { IPropTypes } from './ContactInformationForm.types';
import styles from './ContactInformationForm.module.scss';

export function ContactInformationForm(props: IPropTypes): JSX.Element {
    const { initialValues, validationSchema, isLoading, onBack, onSubmit } =
        props;

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ isValid }) => (
                <Form className={styles.ContactInformationForm}>
                    <div className={styles.ContactInformationForm_form}>
                        <FormikInput
                            id="name"
                            name="name"
                            label="Name"
                            placeholder="Name"
                        />
                        <FormikInput
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                        />
                        <FormikInput
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone"
                            placeholder="Phone"
                        />
                    </div>

                    <div className={styles.ContactInformationForm_actions}>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onBack}
                            className={styles.Button}
                            disabled={isLoading}
                        >
                            Back
                        </Button>
                        <Button
                            type="submit"
                            disabled={!isValid}
                            onClick={() => {
                                // onSubmit handles the form event
                            }}
                            className={styles.Button}
                            isLoading={isLoading}
                        >
                            Continue
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default ContactInformationForm;
