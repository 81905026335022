import { ILeadPages } from 'types';

export const leadPages: ILeadPages = {
    recommended: [
        {
            id: 1,
            name: 'Home',
            slug: 'home',
            isShown: true,
        },
        {
            id: 2,
            name: 'About Us',
            slug: 'about-us',
            isShown: true,
        },
        {
            id: 3,
            name: 'Services',
            slug: 'services',
            isShown: true,
        },
        {
            id: 4,
            name: 'Contact',
            slug: 'contact',
            isShown: true,
        },
    ],
    optional: [],
};
