import React, { useEffect } from 'react';
import { ToastContainer, showToast } from 'spoton-lib';

import { DomainForm } from 'components';
import { domainSchema, useRouter, useTldList } from 'utils';
import { useUpdateLeadDomainNameMutation } from 'services';
import { EDemoSteps } from 'types';

import { IPropTypes } from './NewDomain.types';
import styles from './NewDomain.module.scss';

export function NewDomain(props: IPropTypes): JSX.Element {
    const { leadId } = props;

    const router = useRouter();
    const tldList = useTldList();

    const [updateLeadDomainName, { isError, isLoading, error }] =
        useUpdateLeadDomainNameMutation();

    useEffect(() => {
        if (isError && !isLoading) {
            if ((error as any)?.message) {
                showToast({
                    variant: 'danger',
                    content: (error as any)?.message,
                    containerId: 'domain-toast',
                });
            } else {
                showToast({
                    variant: 'danger',
                    content: 'Something went wrong. Please try again.',
                    containerId: 'domain-toast',
                });
            }
        }
    }, [isError]);

    const handleDomainSubmit = (domain: string, tld: string) => {
        updateLeadDomainName({
            name: domain,
            postfix: `.${tld}`,
            shouldValidate: true,
            leadId,
            currentStep: EDemoSteps.domainPreference,
            connect: false,
        }).then((result) => {
            if ('data' in result) {
                const { url } = router.match;
                const rootDomainUrl = url.substring(0, url.lastIndexOf('/'));

                router.push(
                    `${rootDomainUrl}/existing?domain=${domain}.${tld}`,
                );
            }
        });
    };

    return (
        <div className={styles.NewDomainPageContent}>
            <DomainForm
                buttonText="Search"
                label="Search for an available domain"
                onSubmit={handleDomainSubmit}
                tldList={tldList}
                validationSchema={domainSchema}
                isLoading={isLoading}
            />
            <div className={styles.Toast}>
                <ToastContainer
                    containerId="domain-toast"
                    stylePosition="relative"
                    className={styles.Toast_content}
                />
            </div>
        </div>
    );
}

export default NewDomain;
