import { EDemoSteps, DEMO_STEPS } from 'types';

export const getArrayFromStepsEnum = (): Array<EDemoSteps> => {
    return Object.keys(DEMO_STEPS) as Array<EDemoSteps>;
};

export const getNextStep = (lastStep: EDemoSteps): EDemoSteps => {
    if (lastStep === EDemoSteps.finalDemo) {
        return EDemoSteps.finalDemo;
    }

    const lastStepIndex = DEMO_STEPS[lastStep].index;

    const step = getArrayFromStepsEnum().find((step) => {
        return DEMO_STEPS[step].index === lastStepIndex + 1;
    });

    return step ? step : EDemoSteps.businessInfo;
};

export const getPreviousStep = (lastStep: EDemoSteps): EDemoSteps => {
    if (lastStep === EDemoSteps.businessInfo) {
        // TODO: we would probably want to return to landing page
        return EDemoSteps.businessInfo;
    }

    const lastStepIndex = DEMO_STEPS[lastStep].index;

    const step = getArrayFromStepsEnum().find((step) => {
        return DEMO_STEPS[step].index === lastStepIndex - 1;
    });

    return step ? step : EDemoSteps.businessInfo;
};

export const getUpdatedStep = (
    currentStep: EDemoSteps,
    lastStep: EDemoSteps,
): EDemoSteps => {
    const currentStepIndex = DEMO_STEPS[currentStep].index;
    const lastStepIndex = DEMO_STEPS[lastStep].index;

    return currentStepIndex <= lastStepIndex ? lastStep : getNextStep(lastStep);
};
